import { Link } from "react-router-dom";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";
import { useEffect } from "react";
export default function TermsOfUse() {
  useEffect(() => {
    document.title = "NK Podsused | Uvjeti korištenja";
  }, []);
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UVJETI KORIŠTENJA"
      />

      <div className="section privacy-terms ">
        <p>Datum zadnje izmjene: 17.03.2024</p>
        <p>
          Molimo vas da pažljivo pročitate ove uvjete korištenja prije nego što
          pristupite ili koristite web stranicu NK Podsused. Korištenjem ove web
          stranice potvrđujete da ste pročitali, razumjeli i pristali na sve
          uvjete korištenja navedene u nastavku.
        </p>
        <ul>
          <li>
            <h3>Pravila korištenja</h3>
            <p>
              Korisnici su dužni koristiti web stranicu u skladu s važećim
              zakonima i propisima te se suzdržavati od bilo kakvih aktivnosti
              koje bi mogle štetiti web stranici, drugim korisnicima ili trećim
              stranama.
            </p>
          </li>

          <li>
            <h3>Intelektualno vlasništvo</h3>
            <p>
              Sav sadržaj na ovoj web stranici, uključujući tekstove, grafike,
              logotipe, ikone, slike, audio i video zapise, zaštićen je
              autorskim pravima i drugim zakonima o intelektualnom vlasništvu i
              ne smije se reproducirati ili distribuirati bez prethodnog
              pismenog odobrenja.
            </p>
          </li>
          <li>
            <h3>Poveznice na treće strane</h3>
            <p>
              Web stranica može sadržavati poveznice na web stranice trećih
              strana. NK Podsused nije odgovoran za sadržaj ili pravila
              privatnosti tih web stranica te se preporučuje da pročitate
              njihove uvjete korištenja i politiku privatnosti prije interakcije
              s njima.
            </p>
          </li>
          <li>
            <h3>Ograničenje odgovornosti</h3>
            <p>
              NK Podsused ne daje nikakve garancije u vezi s točnošću,
              potpunosti ili pouzdanošću informacija na ovoj web stranici.
              Korištenjem ove web stranice prihvaćate sve rizike i odgovornosti
              koji proizlaze iz takvog korištenja.
            </p>
          </li>
          <li>
            <h3>Izmjene uvjeta korištenja</h3>
            <p>
              Ovi uvjeti korištenja mogu se povremeno mijenjati. Sve promjene
              bit će objavljene na ovoj web stranici.
            </p>
          </li>
          <li>
            <h3>Kontakt informacije</h3>
            <p>
              Za dodatne informacije o uvjetima korištenja ili za postavljanje
              pitanja, možete nas kontaktirati putem
              <Link className="links" to="podsused.nk@gmail.com">
                podsused.nk@gmail.com
              </Link>
              .
            </p>
          </li>
        </ul>
        <p>Hvala vam što ste pročitali naše uvjete korištenja.</p>
      </div>
    </>
  );
}
