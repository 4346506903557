import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import { useNavigate } from "react-router-dom";

export default function OstaloSeason() {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const podsusedRef = doc(db, "podsused", "podsused");
        const podsusedDoc = await getDoc(podsusedRef);

        if (podsusedDoc.exists()) {
          const data = podsusedDoc.data();
          const ostaleUtakmice = data.ostaleUtakmice || [];

          // Updated category order from oldest to youngest
          const categoryOrder = [
            "Veterani",
            "Seniori",
            "Kadeti 1.ZNL", // Ensure this matches Firestore
            "Pioniri 1.ZNL",
            "Pioniri 3.ZNL",
            "Mlađi Pioniri",
            "Limači",
            "Zagići",
          ];

          // Sort matches based on categoryOrder
          const sortedMatches = ostaleUtakmice.sort((a, b) => {
            const aCategory = a.category.trim(); // Normalize by trimming whitespace
            const bCategory = b.category.trim(); // Normalize by trimming whitespace

            return (
              categoryOrder.indexOf(aCategory) -
              categoryOrder.indexOf(bCategory)
            );
          });

          setMatches(sortedMatches); // Set matches to sorted array
        } else {
          setMessage("No such document!");
          setMatches([]);
        }
      } catch (error) {
        setMessage("Error fetching matches");
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, []); // Load data only on component mount

  // Format date to `dd.mm.yyyy`
  const formatDate = (dateString) => {
    if (dateString.includes(".")) {
      return dateString;
    }
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="OSTALE UTAKMICE"
      />
      <div className="section">
        <button className="go-back-btn" onClick={() => navigate("/")}>
          Vrati se nazad
        </button>
        {loading ? (
          <p>Učitavanje...</p>
        ) : matches.length === 0 ? (
          <div className="info-box">
            <p>{message || "Trenutno nema odigranih utakmica!"}</p>
            <span>NK Podsused</span>
          </div>
        ) : (
          <ul>
            {matches.map((match, index) => (
              <li key={index} className="match-info">
                <h3 className="selection-category">{match.category}</h3>
                <div className="match-result">
                  <div className="team-info">
                    <h3 className="team-name">{match.homeTeam}</h3>
                  </div>
                  <div className="final-result">
                    <h2>{match.result}</h2>
                  </div>
                  <div className="team-info">
                    <h3 className="team-name">{match.awayTeam}</h3>
                  </div>
                </div>
                <div>
                  <h5>
                    <i className="fi fi-rs-calendar-days"></i>
                    {formatDate(match.date)} - {match.time}
                  </h5>
                  <h5>
                    <i className="fi fi-rs-marker"></i>
                    {match.location}
                  </h5>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}
