import React, { useState, useEffect } from "react";

export default function BackToTopButton() {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    // Function to handle scrolling
    const handleScroll = () => {
      if (window.scrollY > (100 * window.innerHeight) / 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    setIsClosing(true); // Set the closing state to true before scrolling to top
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setIsClosing(false); // Set the closing state back to false after scrolling to top
    }, 400); // Same duration as the animation
  };

  return (
    <button
      className={`back-to-top-btn ${isVisible ? "visible" : ""} ${
        isClosing ? "closing" : ""
      }`}
      onClick={scrollToTop}
    >
      <i className="fi fi-rs-arrow-small-up"></i>
    </button>
  );
}
