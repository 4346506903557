import { Link } from "react-router-dom";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = "NK Podsused | Politika privatnosti";
  }, []);
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="POLITIKA PRIVATNOSTI"
      />

      <div className="section privacy-terms">
        <p>Datum zadnje izmjene: 17.03.2024</p>
        <p>
          Hvala što koristite web stranicu NK Podsused. Ova politika privatnosti
          opisuje kako prikupljamo, koristimo i štitimo vaše osobne podatke u
          skladu s važećim zakonima Republike Hrvatske.
        </p>
        <ul>
          <li>
            <h3>Osobni podaci koje prikupljamo</h3>
            <p>
              Prilikom posjete naše web stranice, možemo prikupljati određene
              osobne podatke poput IP adrese, vrste preglednika, jezika
              preglednika, podatke o korištenju web stranice i slično. Ovi
              podaci mogu biti prikupljeni putem kolačića ili sličnih
              tehnologija.
            </p>
          </li>
          <li>
            <h3>Svrha obrade osobnih podataka</h3>
            <p>
              Osobne podatke prikupljamo kako bismo vam pružili bolje korisničko
              iskustvo, analizirali korištenje web stranice, prilagodili sadržaj
              i oglase vašim interesima te osigurali sigurnost web stranice.
            </p>
          </li>
          <li>
            <h3>Pohrana osobnih podataka</h3>
            <p>
              Vaše osobne podatke čuvamo samo onoliko dugo koliko je potrebno za
              ostvarivanje svrhe za koju su prikupljeni ili u skladu s važećim
              zakonima.
            </p>
          </li>
          <li>
            <h3>Dijeljenje osobnih podataka</h3>
            <p>
              Vaše osobne podatke nećemo dijeliti s trećim stranama osim ako to
              nije nužno za pružanje usluga koje ste zatražili ili ako to
              zahtijeva zakon.
            </p>
          </li>
          <li>
            <h3>Vaša prava</h3>
            <p>
              Imate pravo zatražiti pristup, ispravak, brisanje ili ograničenje
              obrade vaših osobnih podataka. Također imate pravo na prenosivost
              podataka i prigovor na način obrade vaših podataka.
            </p>
          </li>
          <li>
            <h3>Kolačići</h3>
            <p>
              Naša web stranica može koristiti kolačiće kako bi poboljšala
              funkcionalnost i prilagodila sadržaj vašim interesima. Ako ne
              želite primati kolačiće, možete ih onemogućiti u postavkama
              preglednika.
            </p>
          </li>
          <li>
            <h3>Izmjene politike privatnosti</h3>
            <p>
              Ova politika privatnosti može se povremeno mijenjati. Sve promjene
              bit će objavljene na ovoj web stranici
            </p>
          </li>
          <li>
            <h3>Kontakt informacije</h3>
            <p>
              Za dodatne informacije o našoj politici privatnosti ili za
              postavljanje pitanja, možete nas kontaktirati putem{" "}
              <Link className="links" to="podsused.nk@gmail.com">
                podsused.nk@gmail.com
              </Link>
              .
            </p>
          </li>
        </ul>
        <p>Hvala vam što ste pročitali našu politiku privatnosti.</p>
      </div>
    </>
  );
}
