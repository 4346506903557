import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebaseConfig";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import Main from "../../Pages/Home/Main";
import { useNavigate } from "react-router-dom";

// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function AddSeniors() {
  const [players, setPlayers] = useState([]);
  const [newPlayer, setNewPlayer] = useState({
    name: "",
    number: "",
    position: "",
    birth: "",
    foot: "",
    image: null,
  });
  const [editingPlayerId, setEditingPlayerId] = useState(null);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  // Učitavanje postojećih igrača iz Firestore
  useEffect(() => {
    const fetchPlayers = async () => {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      const podsusedDoc = await getDoc(podsusedDocRef);
      if (podsusedDoc.exists()) {
        const data = podsusedDoc.data();
        setPlayers(data.seniori || []); // Get the senior players array
      }
    };

    fetchPlayers();
  }, []);

  // Dodavanje novog igrača
  const addPlayer = async () => {
    try {
      let imageUrl = "";
      if (newPlayer.image) {
        const imageRef = ref(storage, `senioriSlike/${newPlayer.image.name}`);
        await uploadBytes(imageRef, newPlayer.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      // Dodavanje novog igrača u array seniori u dokumentu podsused
      const podsusedDocRef = doc(db, "podsused", "podsused");
      await updateDoc(podsusedDocRef, {
        seniori: [
          ...players,
          {
            id: new Date().getTime().toString(),
            ...newPlayer,
            image: imageUrl || "",
          }, // Generating a unique ID
        ],
      });

      // Resetovanje forme
      setNewPlayer({
        name: "",
        number: "",
        position: "",
        birth: "",
        foot: "",
        image: null,
      });

      setMessage("Igrač dodan!");
      // Refresh players list
      setPlayers((prevPlayers) => [
        ...prevPlayers,
        {
          id: new Date().getTime().toString(),
          ...newPlayer,
          image: imageUrl || "",
        },
      ]);
    } catch (error) {
      setMessage("Greška pri dodavanju igrača.");
    }
  };

  // Ažuriranje igrača
  const updatePlayer = async () => {
    try {
      let imageUrl = newPlayer.image;
      if (typeof newPlayer.image !== "string" && newPlayer.image) {
        const imageRef = ref(storage, `senioriSlike/${newPlayer.image.name}`);
        await uploadBytes(imageRef, newPlayer.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const podsusedDocRef = doc(db, "podsused", "podsused");
      await updateDoc(podsusedDocRef, {
        seniori: players.map((player) =>
          player.id === editingPlayerId
            ? {
                ...player,
                name: newPlayer.name,
                number: newPlayer.number,
                position: newPlayer.position,
                birth: newPlayer.birth,
                foot: newPlayer.foot,
                image: imageUrl || "",
              }
            : player
        ),
      });

      setEditingPlayerId(null);
      setNewPlayer({
        name: "",
        number: "",
        position: "",
        birth: "",
        foot: "",
        image: null,
      });

      setMessage("Igrač ažuriran!");
      // Refresh players list
      setPlayers((prevPlayers) =>
        prevPlayers.map((player) =>
          player.id === editingPlayerId
            ? {
                ...player,
                name: newPlayer.name,
                number: newPlayer.number,
                position: newPlayer.position,
                birth: newPlayer.birth,
                foot: newPlayer.foot,
                image: imageUrl || "",
              }
            : player
        )
      );
    } catch (error) {
      setMessage("Greška pri ažuriranju igrača.");
    }
  };

  // Brisanje igrača
  const deletePlayer = async (id) => {
    try {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      await updateDoc(podsusedDocRef, {
        seniori: players.filter((player) => player.id !== id),
      });

      setPlayers((prevPlayers) =>
        prevPlayers.filter((player) => player.id !== id)
      );

      setMessage("Igrač obrisan!");
    } catch (error) {
      console.error("Greška pri brisanju igrača:", error); // Log error
      setMessage("Greška pri brisanju igrača."); // Notify user
    }
  };

  // Uređivanje igrača
  const editPlayer = (player) => {
    setNewPlayer({
      name: player.name,
      number: player.number,
      position: player.position,
      birth: player.birth,
      foot: player.foot,
      image: player.image || null,
    });
    setEditingPlayerId(player.id);
  };

  // Rukovanje promjenama input polja za igrače
  const handlePlayerChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewPlayer((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setNewPlayer((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI SENIORE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <h2>{editingPlayerId ? "Uredi igrača" : "Dodaj novog igrača"}</h2>

        {message && <div className="message">{message}</div>}

        <div className="form">
          <label>Ime:</label>
          <input
            type="text"
            name="name"
            value={newPlayer.name}
            onChange={handlePlayerChange}
          />

          <label>Broj:</label>
          <input
            type="text"
            name="number"
            value={newPlayer.number}
            onChange={handlePlayerChange}
          />

          <label>Pozicija:</label>
          <select
            name="position"
            value={newPlayer.position}
            onChange={handlePlayerChange}
          >
            <option value="">Odaberi poziciju</option>
            <option value="Golman">Golman</option>
            <option value="Lijevi bek">Lijevi bek</option>
            <option value="Stoper">Stoper</option>
            <option value="Desni bek">Desni bek</option>
            <option value="Vezni">Vezni</option>
            <option value="Lijevo krilo">Lijevo krilo</option>
            <option value="Desno krilo">Desno krilo</option>
            <option value="Napadač">Napadač</option>
          </select>

          <label>Datum rođenja:</label>
          <input
            type="date"
            name="birth"
            value={newPlayer.birth}
            onChange={handlePlayerChange}
          />

          <label>Jača noga:</label>
          <select
            name="foot"
            value={newPlayer.foot}
            onChange={handlePlayerChange}
          >
            <option value="">Odaberi jaču nogu</option>
            <option value="Lijeva">Lijeva</option>
            <option value="Desna">Desna</option>
          </select>

          <label>Slika:</label>
          <input type="file" name="image" onChange={handlePlayerChange} />

          <button
            className="add-btn"
            onClick={editingPlayerId ? updatePlayer : addPlayer}
          >
            {editingPlayerId ? "Ažuriraj igrača" : "Dodaj igrača"}
          </button>
        </div>
        <div>
          <h2 className="add-margin">Popis igrača</h2>
        </div>

        <div className="podsused-team add-seniors">
          <h3 className="center">Golmani</h3>
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Golman")
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Obrana</h3>
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Lijevi bek" ||
                  player.position === "Stoper" ||
                  player.position === "Desni bek"
              )
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Vezni igrači</h3>
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Vezni")
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Napadači</h3>
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Napadač" ||
                  player.position === "Lijevo krilo" ||
                  player.position === "Desno krilo"
              )
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
