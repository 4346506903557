import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Kadeti() {
  const [players, setPlayers] = useState([]); // State for players
  const [coach, setCoach] = useState(null); // State for the coach
  const [matches1ZNL, setMatches1ZNL] = useState([]); // State for 1.ZNL matches
  const [matches2ZNL, setMatches2ZNL] = useState([]); // State for 2.ZNL matches
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showImage, setShowImage] = useState(null); // State to display player image
  const [playerInfo, setPlayerInfo] = useState({}); // Player info for the overlay
  const [view, setView] = useState("matches1ZNL"); // State to manage view ("matches1ZNL", "matches2ZNL" or "team")

  useEffect(() => {
    document.title = "NK Podsused | Kadeti";

    const fetchPlayersCoachesAndMatches = async () => {
      try {
        const podsusedDocRef = doc(db, "podsused", "podsused");
        const podsusedDoc = await getDoc(podsusedDocRef);

        if (podsusedDoc.exists()) {
          const data = podsusedDoc.data();
          setPlayers(data.kadeti || []); // Set players data

          const kadetiCoach = (data.treneri || []).find(
            (coach) => coach.selection === "Kadeti"
          );

          if (kadetiCoach) {
            setCoach(kadetiCoach); // Set coach data
          } else {
            setError("Trener za Kadete nije pronađen.");
          }

          // Fetch matches for 1.ZNL
          if (data.kadetiUtakmice_1ZNL) {
            const matches1 = data.kadetiUtakmice_1ZNL.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            );
            setMatches1ZNL(matches1);
          } else {
            setError("Utakmice za 1.ZNL nisu pronađene.");
          }

          // Fetch matches for 2.ZNL
          if (data.kadetiUtakmice_2ZNL) {
            const matches2 = data.kadetiUtakmice_2ZNL.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            );
            setMatches2ZNL(matches2);
          } else {
            setError("Utakmice za 2.ZNL nisu pronađene.");
          }
        } else {
          setError("Podaci nisu pronađeni.");
        }
      } catch (error) {
        setError("Došlo je do pogreške prilikom dohvaćanja podataka.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlayersCoachesAndMatches();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  const openImage = (image, info) => {
    setShowImage(image);
    setPlayerInfo(info);
  };

  const closeImage = () => {
    setShowImage(null);
    setPlayerInfo({});
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="KADETI"
      />

      <div className="section">
        {/* Buttons for toggling views */}
        <div className="show-btn-group center">
          <button
            className={`show-btn ${view === "matches1ZNL" ? "selected" : ""}`}
            onClick={() => setView("matches1ZNL")}
          >
            1.ZNL
          </button>
          <button
            className={`show-btn ${view === "matches2ZNL" ? "selected" : ""}`}
            onClick={() => setView("matches2ZNL")}
          >
            2.ZNL
          </button>
          <button
            className={`show-btn ${view === "team" ? "selected" : ""}`}
            onClick={() => setView("team")}
          >
            Momčad
          </button>
        </div>
        {loading && <p className="center">Učitavanje...</p>}
        {error && <p className="center">{error}</p>}
        {/* Render Matches for 1.ZNL */}
        {view === "matches1ZNL" && (
          <ul>
            {matches1ZNL.map((match, index) => (
              <li key={index} className="match-info">
                <h2 className="selection-category">{match.round}</h2>
                <div className="match-result">
                  <div className="team-info">
                    <h3 className="team-name">{match.homeTeam}</h3>
                  </div>
                  <div className="final-result">
                    <h2>{match.result}</h2>
                  </div>
                  <div className="team-info">
                    <h3 className="team-name">{match.awayTeam}</h3>
                  </div>
                </div>
                <div>
                  <h5>
                    <i className="fi fi-rs-calendar-days"></i>
                    {formatDate(match.date)} - {match.time}
                  </h5>
                  <h5>
                    <i className="fi fi-rs-marker"></i>
                    {match.location}
                  </h5>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* Render Matches for 2.ZNL */}
        {view === "matches2ZNL" && (
          <ul>
            {matches2ZNL.map((match, index) => (
              <li key={index} className="match-info">
                <h2 className="selection-category">{match.round}</h2>
                <div className="match-result">
                  <div className="team-info">
                    <h3 className="team-name">{match.homeTeam}</h3>
                  </div>
                  <div className="final-result">
                    <h2>{match.result}</h2>
                  </div>
                  <div className="team-info">
                    <h3 className="team-name">{match.awayTeam}</h3>
                  </div>
                </div>
                <div>
                  <h5>
                    <i className="fi fi-rs-calendar-days"></i>
                    {formatDate(match.date)} - {match.time}
                  </h5>
                  <h5>
                    <i className="fi fi-rs-marker"></i>
                    {match.location}
                  </h5>
                </div>
              </li>
            ))}
          </ul>
        )}

        {/* Render Team */}
        {view === "team" && (
          <div>
            {/* Sections for different positions */}
            <h3 className="center">NAPADAČI</h3>
            <div className="team-grid ">
              {players
                .filter((player) =>
                  ["Napadač", "Lijevo krilo", "Desno krilo"].includes(
                    player.position
                  )
                )
                .sort((a, b) => Number(a.number) - Number(b.number))
                .map((player) => (
                  <Players
                    key={player.id}
                    image={player.image || NoPlayer}
                    name={player.name}
                    number={player.number}
                    info={{
                      birth: player.birth,
                      position: player.position,
                    }}
                    openImage={openImage}
                  />
                ))}
            </div>
            {/* *****VEZNI IGRAČI***** */}
            <h3 className="center">VEZNI IGRAČI</h3>
            <div className="team-grid">
              {players
                .filter((player) => player.position === "Vezni")
                .sort((a, b) => Number(a.number) - Number(b.number))
                .map((player) => (
                  <Players
                    key={player.id}
                    image={player.image || NoPlayer}
                    name={player.name}
                    number={player.number}
                    info={{
                      birth: player.birth,
                      position: player.position,
                      foot: player.foot,
                    }}
                    openImage={openImage}
                  />
                ))}
            </div>

            {/* *****OBRANA***** */}
            <h3 className="center">OBRANA</h3>
            <div className="team-grid">
              {players
                .filter(
                  (player) =>
                    player.position === "Lijevi bek" ||
                    player.position === "Stoper" ||
                    player.position === "Desni bek"
                )
                .sort((a, b) => Number(a.number) - Number(b.number))
                .map((player) => (
                  <Players
                    key={player.id}
                    image={player.image || NoPlayer}
                    name={player.name}
                    number={player.number}
                    info={{
                      birth: player.birth,
                      position: player.position,
                      foot: player.foot,
                    }}
                    openImage={openImage}
                  />
                ))}
            </div>

            {/* *****GOLMANI***** */}
            <h3 className="center">GOLMANI</h3>
            <div className="team-grid">
              {players
                .filter((player) => player.position === "Golman")
                .sort((a, b) => Number(a.number) - Number(b.number))
                .map((player) => (
                  <Players
                    key={player.id}
                    image={player.image || NoPlayer}
                    name={player.name}
                    number={player.number}
                    info={{
                      birth: player.birth,
                      position: player.position,
                      foot: player.foot,
                    }}
                    openImage={openImage}
                  />
                ))}
            </div>
            {/* Display coach information */}
            <h3 className="add-margin center">TRENER</h3>
            <div className="team-grid">
              {loading && <p className="center">Učitavanje...</p>}
              {error && <p className="center">{error}</p>}
              {!loading && !error && coach && (
                <Coach image={coach.image || NoPlayer} name={coach.name} />
              )}
            </div>
          </div>
        )}

        {/* Overlay for player image */}
        {showImage && (
          <div className="overlay overlay-player" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <img
                id="popupImage"
                src={showImage}
                style={{ width: "100%", height: "100%", userSelect: "none" }}
                onContextMenu={preventContextMenu}
                alt="Show"
              />
              <div className="player-info">
                <h5>Datum rođenja:</h5>
                <p>{formatDate(playerInfo.birth)}</p>
                <h5>Pozicija:</h5>
                <p>{playerInfo.position}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function Players({ image, name, number, info, openImage }) {
  const handleClick = () => {
    openImage(image, info);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="player-box" onClick={handleClick}>
      <img
        src={image}
        alt={name}
        onContextMenu={preventContextMenu}
        style={{ userSelect: "none" }}
      />
      <h3>{name}</h3>
      <p>{number}</p>
    </div>
  );
}

function Coach({ image, name }) {
  return (
    <div className="player-box">
      <img src={image} alt={name} />
      <h3>{name}</h3>
    </div>
  );
}
