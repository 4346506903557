import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

// Images
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";

export default function Pioniri() {
  const [coaches, setCoaches] = useState([]); // Now using an array to store multiple coaches
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "NK Podsused | Pioniri";

    const PODSUSED_DOC_REF = doc(db, "podsused", "podsused");

    const fetchCoaches = async () => {
      try {
        const docSnap = await getDoc(PODSUSED_DOC_REF);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const treneri = data.treneri || [];

          // Find all coaches for "Pioniri"
          const pioniriCoaches = treneri.filter(
            (coach) => coach.selection === "Pioniri"
          );

          if (pioniriCoaches.length > 0) {
            setCoaches(pioniriCoaches); // Set all fetched coaches
          } else {
            setError("Treneri nisu pronađeni.");
          }
        } else {
          setError("Podaci nisu pronađeni.");
        }
      } catch (error) {
        setError("Došlo je do pogreške prilikom dohvaćanja trenera.");
      } finally {
        setLoading(false);
      }
    };

    fetchCoaches();
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="PIONIRI"
      />
      <div className="seniori section">
        <div className="podsused-team">
          <h3>TRENERI</h3>
          <div className="team-grid">
            {loading && <p className="center">Učitavanje...</p>}
            {error && <p className="center">{error}</p>}
            {!loading &&
              !error &&
              coaches.length > 0 &&
              coaches.map((coach) => (
                <Coach
                  key={coach.id}
                  image={coach.image || NoPlayer}
                  name={coach.name}
                />
              ))}
          </div>
        </div>
        <Link
          className="btn"
          to="/ostale-utakmice"
          onClick={() => handleClick("/")}
        >
          Utakmice &rarr;
        </Link>
      </div>
    </>
  );
}

function Coach({ image, name }) {
  return (
    <div className="player-box">
      <img src={image} alt={name} />
      <h3>{name}</h3>
    </div>
  );
}
