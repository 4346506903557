import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebaseConfig";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import Main from "../../Pages/Home/Main";
import { useNavigate } from "react-router-dom";

// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function AddVeterans() {
  const [players, setPlayers] = useState([]);
  const [newPlayer, setNewPlayer] = useState({
    name: "",
    number: "",
    position: "",
    birth: "",
    foot: "",
    image: null,
  });
  const [editingPlayerId, setEditingPlayerId] = useState(null);
  const [message, setMessage] = useState(""); // New message state
  const [coaches, setCoaches] = useState([]); // State for coaches
  const [newCoach, setNewCoach] = useState({
    name: "",
    image: null,
  });
  const [editingCoachId, setEditingCoachId] = useState(null); // For editing coaches
  const navigate = useNavigate();

  // Učitavanje postojećih igrača i trenera iz Firestore
  useEffect(() => {
    const fetchPlayersAndCoaches = async () => {
      const playersSnapshot = await getDocs(collection(db, "urediVeterane"));
      const playersData = playersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPlayers(playersData);

      const coachesSnapshot = await getDocs(collection(db, "treneri"));
      const coachesData = coachesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCoaches(coachesData);
    };

    fetchPlayersAndCoaches();
  }, []);

  // Dodavanje novog igrača
  const addPlayer = async () => {
    try {
      let imageUrl = "";
      if (newPlayer.image) {
        const imageRef = ref(storage, `veteraniSlike/${newPlayer.image.name}`);
        await uploadBytes(imageRef, newPlayer.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const docRef = await addDoc(collection(db, "urediVeterane"), {
        name: newPlayer.name,
        number: newPlayer.number,
        position: newPlayer.position,
        birth: newPlayer.birth,
        foot: newPlayer.foot,
        image: imageUrl || "",
      });

      setPlayers((prevPlayers) => [
        ...prevPlayers,
        { ...newPlayer, id: docRef.id, image: imageUrl || "" },
      ]);

      setNewPlayer({
        name: "",
        number: "",
        position: "",
        birth: "",
        foot: "",
        image: null,
      });

      setMessage("Igrač dodan!"); // Set success message
    } catch (error) {
      setMessage("Greška pri dodavanju igrača."); // Set error message
    }
  };

  // Ažuriranje igrača
  const updatePlayer = async () => {
    try {
      const playerDoc = doc(db, "urediVeterane", editingPlayerId);
      let imageUrl = newPlayer.image;
      if (typeof newPlayer.image !== "string" && newPlayer.image) {
        const imageRef = ref(storage, `veteraniSlike/${newPlayer.image.name}`);
        await uploadBytes(imageRef, newPlayer.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await updateDoc(playerDoc, {
        name: newPlayer.name,
        number: newPlayer.number,
        position: newPlayer.position,
        birth: newPlayer.birth,
        foot: newPlayer.foot,
        image: imageUrl || "",
      });

      setPlayers((prevPlayers) =>
        prevPlayers.map((player) =>
          player.id === editingPlayerId
            ? {
                ...player,
                name: newPlayer.name,
                number: newPlayer.number,
                position: newPlayer.position,
                birth: newPlayer.birth,
                foot: newPlayer.foot,
                image: imageUrl || "",
              }
            : player
        )
      );

      setEditingPlayerId(null);
      setNewPlayer({
        name: "",
        number: "",
        position: "",
        birth: "",
        foot: "",
        image: null,
      });

      setMessage("Igrač ažuriran!");
    } catch (error) {
      setMessage("Greška pri ažuriranju igrača.");
    }
  };

  // Brisanje igrača
  const deletePlayer = async (id) => {
    try {
      await deleteDoc(doc(db, "urediSeniore", id));
      setPlayers((prevPlayers) =>
        prevPlayers.filter((player) => player.id !== id)
      );
      setMessage("Igrač obrisan!");
    } catch (error) {
      setMessage("Greška pri brisanju igrača.");
    }
  };

  // Uređivanje igrača
  const editPlayer = (player) => {
    setNewPlayer({
      name: player.name,
      number: player.number,
      position: player.position,
      birth: player.birth,
      foot: player.foot,
      image: player.image || null,
    });
    setEditingPlayerId(player.id);
  };

  // Rukovanje promjenama input polja za igrače
  const handlePlayerChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewPlayer((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setNewPlayer((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // Dodavanje novog trenera
  const addCoach = async () => {
    try {
      let imageUrl = "";
      if (newCoach.image) {
        const imageRef = ref(storage, `treneriSlike/${newCoach.image.name}`);
        await uploadBytes(imageRef, newCoach.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const docRef = await addDoc(collection(db, "treneri"), {
        name: newCoach.name,
        image: imageUrl || "",
      });

      setCoaches((prevCoaches) => [
        ...prevCoaches,
        { ...newCoach, id: docRef.id, image: imageUrl || "" },
      ]);

      setNewCoach({
        name: "",
        image: null,
      });

      setMessage("Trener dodan!"); // Set success message
    } catch (error) {
      setMessage("Greška pri dodavanju trenera."); // Set error message
    }
  };

  // Ažuriranje trenera
  const updateCoach = async () => {
    try {
      const coachDoc = doc(db, "treneri", editingCoachId);
      let imageUrl = newCoach.image;
      if (typeof newCoach.image !== "string" && newCoach.image) {
        const imageRef = ref(storage, `treneriSlike/${newCoach.image.name}`);
        await uploadBytes(imageRef, newCoach.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      await updateDoc(coachDoc, {
        name: newCoach.name,
        image: imageUrl || "",
      });

      setCoaches((prevCoaches) =>
        prevCoaches.map((coach) =>
          coach.id === editingCoachId
            ? { ...coach, name: newCoach.name, image: imageUrl || "" }
            : coach
        )
      );

      setEditingCoachId(null);
      setNewCoach({
        name: "",
        image: null,
      });

      setMessage("Trener ažuriran!");
    } catch (error) {
      setMessage("Greška pri ažuriranju trenera.");
    }
  };

  // Brisanje trenera
  const deleteCoach = async (id) => {
    try {
      await deleteDoc(doc(db, "treneri", id));
      setCoaches((prevCoaches) =>
        prevCoaches.filter((coach) => coach.id !== id)
      );
      setMessage("Trener obrisan!"); // Set success message
    } catch (error) {
      setMessage("Greška pri brisanju trenera."); // Set error message
    }
  };

  // Uređivanje trenera
  const editCoach = (coach) => {
    setNewCoach({
      name: coach.name,
      image: coach.image || null,
    });
    setEditingCoachId(coach.id);
  };

  // Rukovanje promjenama input polja za trenere
  const handleCoachChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewCoach((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setNewCoach((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI VETERANE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <h2>{editingPlayerId ? "Uredi igrača" : "Dodaj novog igrača"}</h2>

        {message && <div className="message">{message}</div>}

        <div className="form">
          <label>Ime:</label>
          <input
            type="text"
            name="name"
            value={newPlayer.name}
            onChange={handlePlayerChange}
          />

          <label>Broj:</label>
          <input
            type="text"
            name="number"
            value={newPlayer.number}
            onChange={handlePlayerChange}
          />

          <label>Pozicija:</label>
          <select
            name="position"
            value={newPlayer.position}
            onChange={handlePlayerChange}
          >
            <option value="">Odaberi poziciju</option>
            <option value="Golman">Golman</option>
            <option value="Lijevi bek">Lijevi bek</option>
            <option value="Stoper">Stoper</option>
            <option value="Desni bek">Desni bek</option>
            <option value="Vezni">Vezni</option>
            <option value="Lijevo krilo">Lijevo krilo</option>
            <option value="Desno krilo">Desno krilo</option>
            <option value="Napadač">Napadač</option>
          </select>

          <label>Datum rođenja:</label>
          <input
            type="date"
            name="birth"
            value={newPlayer.birth}
            onChange={handlePlayerChange}
          />

          <label>Slika:</label>
          <input type="file" name="image" onChange={handlePlayerChange} />

          <button
            className="add-btn"
            onClick={editingPlayerId ? updatePlayer : addPlayer}
          >
            {editingPlayerId ? "Ažuriraj igrača" : "Dodaj igrača"}
          </button>
        </div>
        <div>
          <h2 className="add-margin">Popis igrača</h2>
        </div>

        <h3 className="center">Golmani</h3>
        <div className="podsused-team add-seniors">
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Golman")
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Obrana</h3>
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Lijevi bek" ||
                  player.position === "Stoper" ||
                  player.position === "Desni bek"
              )
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Vezni igrači</h3>
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Vezni")
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Napadači</h3>
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Napadač" ||
                  player.position === "Lijevo krilo" ||
                  player.position === "Desno krilo"
              )
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Coach Section */}
        <h3 className="center">Trener</h3>
        <div className="form">
          <label>Ime:</label>
          <input
            type="text"
            name="name"
            value={newCoach.name}
            onChange={handleCoachChange}
          />

          <label>Slika:</label>
          <input type="file" name="image" onChange={handleCoachChange} />

          <button
            className="add-btn"
            onClick={editingCoachId ? updateCoach : addCoach}
          >
            {editingCoachId ? "Ažuriraj trenera" : "Dodaj trenera"}
          </button>
        </div>

        {/* Display the list of coaches */}
        <div className="team-grid">
          {coaches.map((coach) => (
            <div key={coach.id} className="player-item">
              <div className="player-info">
                <img
                  src={coach.image || NoPlayer}
                  alt={coach.name}
                  width="50"
                  height="50"
                />
                <div>
                  <h3>{coach.name}</h3>
                </div>
              </div>
              <div>
                <button className="add-btn" onClick={() => editCoach(coach)}>
                  Uredi
                </button>
                <button
                  className="remove-btn"
                  onClick={() => deleteCoach(coach.id)}
                >
                  Obriši
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
