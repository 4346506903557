import React, { useEffect, useState } from "react";
import { useMembersAuth } from "./MembersAuthProvider";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Main from "../Pages/Home/Main";

// Slike
import NKPodsusedBijelaBg from "../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function MembersHome() {
  const { logout, currentUser } = useMembersAuth();
  const [videoData, setVideoData] = useState([]);
  const [newsItems, setNewsItems] = useState([]);
  const db = getFirestore();

  // Fetch video data and news items from Firestore
  useEffect(() => {
    const fetchMemberData = async () => {
      const članoviDocRef = doc(db, "podsused", "članovi");
      const docSnap = await getDoc(članoviDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setNewsItems((data.članoviNovosti || []).filter((item) => item));

        setVideoData(
          (data.video || []).filter((video) => video && video.videoUrl)
        );
      }
    };

    fetchMemberData();
  }, [db]);

  const handleLogout = async () => {
    if (currentUser) {
      await logout();
    }
  };

  // Kreiraj Google Drive URL
  const getGoogleDriveUrl = (videoUrl) => {
    const urlObj = new URL(videoUrl);
    const videoId = urlObj.pathname.split("/")[3]; // Dobija FILE_ID
    return `https://drive.google.com/file/d/${videoId}/preview`; // Vraća URL za pregled
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="ČLANOVI NK PODSUSED"
      />
      <div className="section">
        <div className="center">
          <button className="remove-btn" onClick={handleLogout}>
            Odjavi se
          </button>
        </div>
        <div className="members-private">
          {/* Display News Items */}
          <h3>Novosti za članove</h3>
          {newsItems.length > 0 ? (
            newsItems.map((news, index) => (
              <div key={index} className="members-news">
                <img src={news.imageUrl} alt={news.altText} />
                <h4>{news.title}</h4>
                {news.introText && <p>{news.introText}</p>}
                <h5>{news.author}</h5>
                <span className="date">{news.date}</span>
              </div>
            ))
          ) : (
            <div className="info-box">Nema novosti za članove!</div>
          )}

          {/* Display Video */}
          <h3>Pogledajte utakmicu</h3>
          {videoData.length > 0 ? (
            videoData.map((video, index) => (
              <div
                key={index}
                className="video"
                onContextMenu={(e) => e.preventDefault()}
              >
                <div className="video-cover"></div>
                <iframe
                  src={getGoogleDriveUrl(video.videoUrl)}
                  width="560"
                  height="315"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title={`Video ${index}`}
                  onContextMenu={(e) => e.preventDefault()}
                ></iframe>
                {video.subtitle && <h4>{video.subtitle}</h4>}
                {video.description && <p>{video.description}</p>}
              </div>
            ))
          ) : (
            <div className="info-box">Nema videozapisa!</div>
          )}
        </div>
      </div>
    </>
  );
}
