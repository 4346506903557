import React from "react";
import { Link } from "react-router-dom";
// Images
import NKPodsused from "../../../Assets/Images/Nk-Podsused-Logo.png";

export default function ErrorPage({ error, hideFooter, hideSponsors }) {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="section error-page">
        <div>
          <img src={NKPodsused} alt="NK Podsused" />
          <h2>DOŠLO JE DO GREŠKE!</h2>
          <p>{error}!</p>
          <p>
            Pokušajte kasnije ili nas kontaktirajte na mail{" "}
            <a href="mailto:podsused.nk@gmail.com" className="footer-links">
              <i className="fi fi-rs-at"></i>podsused.nk@gmail.com
            </a>
            .
          </p>

          <Link className="btn" to="/" onClick={() => handleClick("/")}>
            NATRAG NA NASLOVNU
          </Link>
        </div>
      </div>
    </>
  );
}
