import React, { useState, useEffect } from "react";
import { db } from "../../../firebaseConfig"; // Import Firebase config
import { doc, onSnapshot } from "firebase/firestore"; // Import Firestore functions
import Player from "../../../Assets/Images/Ostalo/Player.png";
import Ball from "../../../Assets/Images/Ostalo/Ball.png";

// Component to display each player row
const TeamRow = ({ player, index }) => {
  return (
    <li className="row">
      <h5>{index}</h5>
      <p>
        <img src={Player} alt={player.name} />
        {player.name}
      </p>
      <p>
        {player.goals} <img src={Ball} alt="Ball" />
      </p>
    </li>
  );
};

// Component to display the player standings
const PlayersStatistics = ({ standingsData }) => {
  return (
    <ul className="statistics">
      <li className="row">
        <h5>#</h5>
        <h5>Strijelac</h5>
        <h5>Golovi</h5>
      </li>
      {standingsData.map((player, index) => (
        <TeamRow key={index} player={player} index={index + 1} />
      ))}
    </ul>
  );
};

// Main component for showing statistics and managing players
export default function Statistics() {
  const [playerStats, setPlayerStats] = useState([]); // Initial empty player list
  const [message, setMessage] = useState(""); // State for messages

  // Fetch players from the podsused document
  useEffect(() => {
    const docRef = doc(db, "podsused", "podsused");

    // Set up real-time subscription to Firestore
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const players = data.strijelci || [];

          const sortedPlayers = players.sort((a, b) => {
            if (b.goals !== a.goals) {
              return b.goals - a.goals;
            }

            const lastNameA = a.name.split(" ").slice(-1)[0].toLowerCase(); // Get last name
            const lastNameB = b.name.split(" ").slice(-1)[0].toLowerCase();
            return lastNameA.localeCompare(lastNameB);
          });

          setPlayerStats(sortedPlayers);
        } else {
          setMessage("Nema podataka za prikaz!");
        }
      },
      (error) => {
        setMessage("Došlo je do greške prilikom dohvaćanja podataka.");
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <>
      {message && <p className="message">{message}</p>}

      <PlayersStatistics standingsData={playerStats} />
    </>
  );
}
