import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../../firebaseConfig";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import { useNavigate } from "react-router-dom";

// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../../Pages/Home/Main";

export default function AddKadeti() {
  const [players, setPlayers] = useState([]);
  const [newPlayer, setNewPlayer] = useState({
    name: "",
    number: "",
    position: "",
    birth: "",
    image: null,
  });
  const [editingPlayerId, setEditingPlayerId] = useState(null);

  const [matches1ZNL, setMatches1ZNL] = useState([]);
  const [matches2ZNL, setMatches2ZNL] = useState([]);

  const [newMatch, setNewMatch] = useState({
    homeTeam: "",
    awayTeam: "",
    result: "",
    date: "",
    time: "",
    location: "",
    round: "",
    league: "1ZNL",
  });

  const [editingMatchId, setEditingMatchId] = useState(null);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const formRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      const podsusedDoc = await getDoc(podsusedDocRef);
      if (podsusedDoc.exists()) {
        const data = podsusedDoc.data();
        setPlayers(data.kadeti || []);
        setMatches1ZNL(data.kadetiUtakmice_1ZNL || []);
        setMatches2ZNL(data.kadetiUtakmice_2ZNL || []);
      }
    };
    fetchData();
  }, []);

  // Dodavanje igrača
  const addPlayer = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = "";
      if (newPlayer.image) {
        const imageRef = ref(storage, `kadetiSlike/${newPlayer.image.name}`);
        await uploadBytes(imageRef, newPlayer.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const podsusedDocRef = doc(db, "podsused", "podsused");
      await updateDoc(podsusedDocRef, {
        kadeti: [
          ...players,
          {
            id: new Date().getTime().toString(),
            ...newPlayer,
            image: imageUrl || "",
          },
        ],
      });

      setNewPlayer({
        name: "",
        number: "",
        position: "",
        birth: "",
        image: null,
      });
      setMessage("Igrač dodan!");
      setPlayers((prevPlayers) => [
        ...prevPlayers,
        {
          id: new Date().getTime().toString(),
          ...newPlayer,
          image: imageUrl || "",
        },
      ]);
    } catch (error) {
      setMessage("Greška pri dodavanju igrača.");
    }
  };

  // Uređivanje igrača
  const updatePlayer = async () => {
    try {
      let imageUrl = newPlayer.image;
      if (typeof newPlayer.image !== "string" && newPlayer.image) {
        const imageRef = ref(storage, `kadetiSlike/${newPlayer.image.name}`);
        await uploadBytes(imageRef, newPlayer.image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const podsusedDocRef = doc(db, "podsused", "podsused");
      await updateDoc(podsusedDocRef, {
        kadeti: players.map((player) =>
          player.id === editingPlayerId
            ? { ...player, ...newPlayer, image: imageUrl || "" }
            : player
        ),
      });

      setEditingPlayerId(null);
      setNewPlayer({
        name: "",
        number: "",
        position: "",
        birth: "",
        image: null,
      });

      setMessage("Igrač ažuriran!");
      setPlayers((prevPlayers) =>
        prevPlayers.map((player) =>
          player.id === editingPlayerId
            ? { ...player, ...newPlayer, image: imageUrl || "" }
            : player
        )
      );
    } catch (error) {
      setMessage("Greška pri ažuriranju igrača.");
    }
  };

  const deletePlayer = async (id) => {
    try {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      await updateDoc(podsusedDocRef, {
        kadeti: players.filter((player) => player.id !== id),
      });

      setPlayers((prevPlayers) =>
        prevPlayers.filter((player) => player.id !== id)
      );
      setMessage("Igrač obrisan!");
    } catch (error) {
      setMessage("Greška pri brisanju igrača.");
    }
  };

  const editPlayer = (player) => {
    setNewPlayer({
      name: player.name,
      number: player.number,
      position: player.position,
      birth: player.birth,
      image: player.image || null,
    });
    setEditingPlayerId(player.id);
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePlayerChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewPlayer((prevState) => ({ ...prevState, [name]: files[0] }));
    } else {
      setNewPlayer((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // Dodavanje i uređivanje utakmica
  const addOrUpdateMatch = async (e) => {
    e.preventDefault();
    const matchData = newMatch.league === "1ZNL" ? matches1ZNL : matches2ZNL;
    const updateField =
      newMatch.league === "1ZNL"
        ? "kadetiUtakmice_1ZNL"
        : "kadetiUtakmice_2ZNL";

    try {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      let updatedMatches;

      if (editingMatchId) {
        updatedMatches = matchData.map((match) =>
          match.id === editingMatchId ? { ...match, ...newMatch } : match
        );
        setEditingMatchId(null);
      } else {
        updatedMatches = [
          ...matchData,
          { ...newMatch, id: new Date().getTime().toString() },
        ];
      }

      await updateDoc(podsusedDocRef, {
        [updateField]: updatedMatches,
      });

      setNewMatch({
        homeTeam: "",
        awayTeam: "",
        result: "",
        date: "",
        time: "",
        location: "",
        round: "",
        league: newMatch.league,
      });
      setMessage(editingMatchId ? "Utakmica ažurirana!" : "Utakmica dodana!");

      if (newMatch.league === "1ZNL") {
        setMatches1ZNL(updatedMatches);
      } else {
        setMatches2ZNL(updatedMatches);
      }
    } catch (error) {
      setMessage("Greška pri dodavanju/uređivanju utakmice.");
    }
  };

  const editMatch = (match) => {
    setNewMatch(match);
    setEditingMatchId(match.id);
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const deleteMatch = async (id, league) => {
    const matchData = league === "1ZNL" ? matches1ZNL : matches2ZNL;
    const updateField =
      league === "1ZNL" ? "kadetiUtakmice_1ZNL" : "kadetiUtakmice_2ZNL";

    try {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      const updatedMatches = matchData.filter((match) => match.id !== id);
      await updateDoc(podsusedDocRef, {
        [updateField]: updatedMatches,
      });

      if (league === "1ZNL") {
        setMatches1ZNL(updatedMatches);
      } else {
        setMatches2ZNL(updatedMatches);
      }
      setMessage("Utakmica obrisana!");
    } catch (error) {
      setMessage("Greška pri brisanju utakmice.");
    }
  };

  const handleMatchChange = (e) => {
    const { name, value } = e.target;
    setNewMatch((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI SENIORE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>

        {/* Forma za dodavanje/uređivanje utakmica */}
        <h4 className="center">DODAJ UTAKMICU</h4>
        <form className="form" onSubmit={addOrUpdateMatch}>
          <select
            name="league"
            value={newMatch.league}
            onChange={handleMatchChange}
          >
            <option value="1ZNL">1. ZNL</option>
            <option value="2ZNL">2. ZNL</option>
          </select>
          <input
            type="text"
            name="round"
            value={newMatch.round}
            onChange={handleMatchChange}
            placeholder="Kolo"
          />
          <input
            type="text"
            name="homeTeam"
            value={newMatch.homeTeam}
            onChange={handleMatchChange}
            placeholder="Domaći tim"
            required
          />
          <input
            type="text"
            name="awayTeam"
            value={newMatch.awayTeam}
            onChange={handleMatchChange}
            placeholder="Gostujući tim"
            required
          />
          <input
            type="text"
            name="result"
            value={newMatch.result}
            onChange={handleMatchChange}
            placeholder="Rezultat"
          />
          <input
            type="date"
            name="date"
            value={newMatch.date}
            onChange={handleMatchChange}
            placeholder="Datum"
            required
          />
          <input
            type="time"
            name="time"
            value={newMatch.time}
            onChange={handleMatchChange}
            placeholder="Vrijeme"
          />
          <input
            type="text"
            name="location"
            value={newMatch.location}
            onChange={handleMatchChange}
            placeholder="Lokacija"
          />

          <button className="add-btn" type="submit">
            {editingMatchId ? "Ažuriraj utakmicu" : "Dodaj utakmicu"}
          </button>
          {/* Poruke */}
          {message && <p className="message">{message}</p>}
        </form>

        {/* Popis utakmica za 1. ZNL */}
        <h2>1. ZNL</h2>
        <ul>
          {matches1ZNL.map((match) => (
            <li className="selection-matches" key={match.id}>
              <div className="info-1">
                <h3 className="selection-category">{match.round}</h3>
                <div>
                  <button className="add-btn" onClick={() => editMatch(match)}>
                    Uredi
                  </button>
                  <button
                    className="remove-btn"
                    onClick={() => deleteMatch(match.id, "2ZNL")}
                  >
                    Obriši
                  </button>
                </div>
              </div>
              <div className="info-2">
                <p className="team-name">{match.homeTeam}</p>
                <h2>{match.result}</h2>
                <p className="team-name">{match.awayTeam}</p>
              </div>
            </li>
          ))}
        </ul>
        <div className="light-border"></div>
        {/* Popis utakmica za 2. ZNL */}
        <h2>2. ZNL</h2>
        <ul>
          {matches2ZNL.map((match) => (
            <li className="selection-matches" key={match.id}>
              <div className="info-1">
                <h3 className="selection-category">{match.round}</h3>
                <div>
                  <button className="add-btn" onClick={() => editMatch(match)}>
                    Uredi
                  </button>
                  <button
                    className="remove-btn"
                    onClick={() => deleteMatch(match.id, "2ZNL")}
                  >
                    Obriši
                  </button>
                </div>
              </div>
              <div className="info-2">
                <p className="team-name">{match.homeTeam}</p>
                <h2>{match.result}</h2>
                <p className="team-name">{match.awayTeam}</p>
              </div>
            </li>
          ))}
        </ul>
        <div className="light-border"></div>
        {/* DODAJ IGRAČA */}
        <h2>DODAJ IGRAČA</h2>
        <form
          className="form"
          ref={formRef}
          onSubmit={editingPlayerId ? updatePlayer : addPlayer}
        >
          <label>Ime:</label>
          <input
            type="text"
            name="name"
            value={newPlayer.name}
            onChange={handlePlayerChange}
            placeholder="Ime i prezime"
            required
          />
          <label>Broj:</label>
          <input
            type="number"
            name="number"
            value={newPlayer.number}
            onChange={handlePlayerChange}
            placeholder="Broj"
            required
          />
          <label>Pozicija:</label>
          <select
            name="position"
            value={newPlayer.position}
            onChange={handlePlayerChange}
          >
            <option value="">Odaberi poziciju</option>
            <option value="Golman">Golman</option>
            <option value="Lijevi bek">Lijevi bek</option>
            <option value="Stoper">Stoper</option>
            <option value="Desni bek">Desni bek</option>
            <option value="Vezni">Vezni</option>
            <option value="Lijevo krilo">Lijevo krilo</option>
            <option value="Desno krilo">Desno krilo</option>
            <option value="Napadač">Napadač</option>
          </select>
          <label>Datum rođenja:</label>
          <input
            type="date"
            name="birth"
            value={newPlayer.birth}
            onChange={handlePlayerChange}
            placeholder="Datum rođenja"
            required
          />
          <label>Slika:</label>
          <input type="file" name="image" onChange={handlePlayerChange} />
          <button className="add-btn" type="submit">
            {editingPlayerId ? "Ažuriraj igrača" : "Dodaj igrača"}
          </button>
        </form>

        {/* Popis igrača */}
        <div className="podsused-team add-seniors">
          <h3 className="center">Golmani</h3>
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Golman")
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Obrana</h3>
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Lijevi bek" ||
                  player.position === "Stoper" ||
                  player.position === "Desni bek"
              )
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Vezni igrači</h3>
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Vezni")
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>

          <h3 className="center">Napadači</h3>
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Napadač" ||
                  player.position === "Lijevo krilo" ||
                  player.position === "Desno krilo"
              )
              .map((player) => (
                <div key={player.id} className="player-item">
                  <div className="player-info">
                    <img
                      src={player.image || NoPlayer}
                      alt={player.name}
                      width="50"
                      height="50"
                    />
                    <div>
                      <h3>{player.name}</h3>
                      <h5>{player.number}</h5>
                    </div>
                  </div>
                  <div>
                    <p>
                      <span>Pozicija:</span> {player.position}
                    </p>
                    <p>
                      <span>Jača noga:</span> {player.foot}
                    </p>
                    <button
                      className="add-btn"
                      onClick={() => editPlayer(player)}
                    >
                      Uredi
                    </button>
                    <button
                      className="remove-btn"
                      onClick={() => deletePlayer(player.id)}
                    >
                      Obriši
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
