import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Adjust this import if needed

const AdminPrivateRoute = ({ children }) => {
  const { isAuthenticated, role } = useAuth(); // Use isAuthenticated and role from AuthProvider

  // Check if the user is authenticated and is an admin
  if (!isAuthenticated || role !== "admin") {
    return <Navigate to="/admin/prijava" />;
  }

  return children; // If authenticated and is admin, render the children
};

export default AdminPrivateRoute;
