import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore"; // Import getDoc to fetch a specific document
import { db } from "../../../firebaseConfig";
import Main from "../Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Gallery() {
  const [galleryItems, setGalleryItems] = useState([]);
  const [showImage, setShowImage] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAllImages, setShowAllImages] = useState(false); // Manage visibility state
  const [warningMessage, setWarningMessage] = useState(""); // State for warning messages

  const fetchGallery = async () => {
    // Get a specific document (podsused) from the podsused collection
    const docRef = doc(db, "podsused", "podsused");
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Extract gallery items from the 'galerija' array within the document
      const galleryData = docSnapshot.data();
      setGalleryItems(galleryData.galerija || []); // Default to empty array if no gallery
    } else {
      // Set warning message if the document does not exist
      setWarningMessage("Dokument ne postoji!");
      setTimeout(() => setWarningMessage(""), 3000); // Clear message after 3 seconds
    }
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  const openImage = (imageSrc, index) => {
    setShowImage(imageSrc);
    setCurrentImageIndex(index);
  };

  const closeImage = () => {
    setShowImage("");
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? galleryItems.length - 1 : prevIndex - 1
    );
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % galleryItems.length);
  };

  const toggleShowAllImages = () => {
    setShowAllImages(!showAllImages);
  };

  // Determine which items to display based on whether we are showing all or not
  const displayedItems = showAllImages
    ? galleryItems
    : galleryItems.slice(0, 6);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="GALERIJA"
      />
      <div className="section">
        {warningMessage && (
          <div className="warning-message">{warningMessage}</div>
        )}
        {galleryItems.length === 0 ? (
          <div className="info-box">
            <p>Trenutno nema dodanih slika.</p>
            <span>NK Podsused</span>
          </div>
        ) : (
          <>
            <div className="gallery">
              {displayedItems.map((item, index) => (
                <img
                  key={index}
                  src={item.imageUrl}
                  alt="Galerija"
                  onClick={() => openImage(item.imageUrl, index)}
                  loading="lazy"
                  onError={(e) => {
                    e.target.style.display = "none"; // Hide image on error
                  }}
                />
              ))}
            </div>
            {/* Show button only if there are more than 6 items */}
            {galleryItems.length > 6 && (
              <button className="btn more-btn" onClick={toggleShowAllImages}>
                {showAllImages ? "Prikaži manje" : "Prikaži sve"}
              </button>
            )}
          </>
        )}
        {showImage && galleryItems.length > 0 && (
          <div className="overlay" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <button className="prev popup-btn" onClick={(e) => prevImage(e)}>
                &#10094;
              </button>
              <button className="next popup-btn" onClick={(e) => nextImage(e)}>
                &#10095;
              </button>
              <img
                id="popupImage"
                src={galleryItems[currentImageIndex].imageUrl}
                alt="Show"
                loading="lazy"
              />
              <span className="img-author">
                FOTO: {galleryItems[currentImageIndex].author}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
