import React, { useState } from "react";
import { useAuth } from "./AuthProvider"; // Provjerite putanju
import { useNavigate } from "react-router-dom";

// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../../Pages/Home/Main";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { login, role } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);

      // Navigiraj prema ulozi
      if (role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/member/dashboard"); // Prilagodite ovu putanju prema vašem članu
      }
    } catch (error) {
      setMessage("Pogrešni podaci. Molimo pokušajte ponovno.");
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="ADMIN PRIJAVA"
      />
      <div className="section">
        <form className="login-box" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Lozinka"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="error-message">{message}</div>
          <button className="add-btn" type="submit">
            Prijavi se
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
