import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Seniors() {
  const [players, setPlayers] = useState([]); // State for players
  const [coach, setCoach] = useState(null); // State for the coach
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [showImage, setShowImage] = useState(null); // State to display player image
  const [playerInfo, setPlayerInfo] = useState({}); // Player info for the overlay

  useEffect(() => {
    document.title = "NK Podsused | Seniori";

    // Fetch players and coaches from Firestore
    const fetchPlayersAndCoach = async () => {
      try {
        const podsusedDocRef = doc(db, "podsused", "podsused");
        const podsusedDoc = await getDoc(podsusedDocRef);

        if (podsusedDoc.exists()) {
          const data = podsusedDoc.data();
          setPlayers(data.seniori || []); // Set players data

          // Find the coach for "Seniori"
          const senioriCoach = (data.treneri || []).find(
            (coach) => coach.selection === "Seniori"
          );

          if (senioriCoach) {
            setCoach(senioriCoach); // Set coach data
          } else {
            setError("Trener za seniore nije pronađen.");
          }
        } else {
          setError("Podaci nisu pronađeni.");
        }
      } catch (error) {
        setError("Došlo je do pogreške prilikom dohvaćanja podataka.");
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchPlayersAndCoach(); // Call the function to fetch data
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  const openImage = (image, info) => {
    setShowImage(image);
    setPlayerInfo(info);
  };

  const closeImage = () => {
    setShowImage(null);
    setPlayerInfo({});
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="SENIORI"
      />

      <div className="section">
        {/* Overlay za prikaz slike igrača */}
        {showImage && (
          <div className="overlay overlay-player" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <img
                id="popupImage"
                src={showImage}
                style={{ width: "100%", height: "100%", userSelect: "none" }}
                onContextMenu={preventContextMenu}
                alt="Show"
              />
              <div className="player-info">
                <h5>Datum rođenja:</h5>
                <p>{formatDate(playerInfo.birth)}</p>
                <h5>Pozicija:</h5>
                <p>{playerInfo.position}</p>
                <h5>Jača noga:</h5>
                <p>{playerInfo.foot}</p>
              </div>
            </div>
          </div>
        )}

        {/* *****NAPADAČI***** */}
        <h3 className="center">NAPADAČI</h3>
        <div className="team-grid">
          {players
            .filter(
              (player) =>
                player.position === "Napadač" ||
                player.position === "Lijevo krilo" ||
                player.position === "Desno krilo"
            )
            .sort((a, b) => Number(a.number) - Number(b.number))
            .map((player) => (
              <Players
                key={player.id}
                image={player.image || NoPlayer}
                name={player.name}
                number={player.number}
                info={{
                  birth: player.birth,
                  position: player.position,
                  foot: player.foot,
                }}
                openImage={openImage}
              />
            ))}
        </div>

        {/* *****VEZNI IGRAČI***** */}
        <h3 className="center">VEZNI IGRAČI</h3>
        <div className="team-grid">
          {players
            .filter((player) => player.position === "Vezni")
            .sort((a, b) => Number(a.number) - Number(b.number))
            .map((player) => (
              <Players
                key={player.id}
                image={player.image || NoPlayer}
                name={player.name}
                number={player.number}
                info={{
                  birth: player.birth,
                  position: player.position,
                  foot: player.foot,
                }}
                openImage={openImage}
              />
            ))}
        </div>

        {/* *****OBRANA***** */}
        <h3 className="center">OBRANA</h3>
        <div className="team-grid">
          {players
            .filter(
              (player) =>
                player.position === "Lijevi bek" ||
                player.position === "Stoper" ||
                player.position === "Desni bek"
            )
            .sort((a, b) => Number(a.number) - Number(b.number))
            .map((player) => (
              <Players
                key={player.id}
                image={player.image || NoPlayer}
                name={player.name}
                number={player.number}
                info={{
                  birth: player.birth,
                  position: player.position,
                  foot: player.foot,
                }}
                openImage={openImage}
              />
            ))}
        </div>

        {/* *****GOLMANI***** */}
        <h3 className="center">GOLMANI</h3>
        <div className="team-grid">
          {players
            .filter((player) => player.position === "Golman")
            .sort((a, b) => Number(a.number) - Number(b.number))
            .map((player) => (
              <Players
                key={player.id}
                image={player.image || NoPlayer}
                name={player.name}
                number={player.number}
                info={{
                  birth: player.birth,
                  position: player.position,
                  foot: player.foot,
                }}
                openImage={openImage}
              />
            ))}
        </div>
        {/* Display coach information */}
        <h2 className="add-margin">TRENER</h2>
        <div className="team-grid">
          {loading && <p className="center">Učitavanje...</p>}
          {error && <p className="center">{error}</p>}
          {!loading && !error && coach && (
            <Coach image={coach.image || NoPlayer} name={coach.name} />
          )}
        </div>
      </div>
    </>
  );
}

function Players({ image, name, number, info, openImage }) {
  const handleClick = () => {
    openImage(image, info);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="player-box" onClick={handleClick}>
      <img
        src={image}
        alt={name}
        onContextMenu={preventContextMenu}
        style={{ userSelect: "none" }}
      />
      <h3>{name}</h3>
      <p>{number}</p>
    </div>
  );
}

function Coach({ image, name }) {
  return (
    <div className="player-box">
      <img src={image} alt={name} />
      <h3>{name}</h3>
    </div>
  );
}
