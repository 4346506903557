import React, { createContext, useContext, useState } from "react";
import { auth } from "../../../firebaseConfig"; // Ensure this path is correct
import { signOut, signInWithEmailAndPassword } from "firebase/auth";

// Create context for Admin authentication
const AdminAuthContext = createContext();

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (!context) {
    throw new Error("useAdminAuth must be used within an AdminAuthProvider");
  }
  return context;
};

const AdminAuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null);

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setAdmin(userCredential.user); // Set the admin
      console.log("Admin logged in:", userCredential.user.email); // Debugging
    } catch (error) {
      console.error("Error logging in:", error);
      throw new Error(error.message); // Handle login errors
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setAdmin(null); // Reset admin state
      console.log("Admin logged out"); // Debugging
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AdminAuthContext.Provider value={{ admin, login, logout }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export default AdminAuthProvider;
