import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig"; // Import Firebase config
import Main from "./Main";

// Images
import RasporedTreningaBg from "../../../Assets/Images/Ostalo/Trening.jpg";

// Static team order array
const teamOrder = [
  "Seniori",
  "Juniori",
  "Kadeti",
  "Mlađi Pioniri",
  "Pioniri",
  "Limači",
  "Zagići",
];

export default function RasporedTreninga() {
  const [trainings, setTrainings] = useState([]);
  const [message, setMessage] = useState(""); // New state for messages
  const trainingRefs = useRef({}); // Create refs for each training component

  // Fetch training data from Firebase
  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        // Get the document that contains the training array
        const docRef = doc(db, "podsused", "podsused"); // Specify the correct document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const fetchedTrainings = data.trening || []; // Get the trening array

          // Sort trainings by predefined team order
          const sortedTrainings = fetchedTrainings.sort(
            (a, b) =>
              teamOrder.indexOf(a.selection) - teamOrder.indexOf(b.selection)
          );

          setTrainings(sortedTrainings);
          if (sortedTrainings.length === 0) {
            setMessage("Trenutno nema spremljenih treninga."); // Set message if no trainings
          }
        } else {
          setMessage("No such document!"); // Set message if document doesn't exist
        }
      } catch (error) {
        setMessage("Error fetching trainings."); // Set message on error
      }
    };

    fetchTrainings();
    document.title = "NK Podsused | Raspored treninga";
  }, []);

  return (
    <>
      <Main
        desktopBackground={RasporedTreningaBg}
        phoneBackground={RasporedTreningaBg}
        title="RASPORED TRENINGA"
      />

      <div className="section raspored-treninga">
        {/* Display message if there is one */}
        {message && (
          <div className="info-box">
            <p>{message}</p>
            <span>NK Podsused</span>
          </div>
        )}

        {/* List of trainings */}
        {trainings.length > 0 &&
          trainings.map((training) => (
            <div
              key={training.id}
              ref={(el) => (trainingRefs.current[training.selection] = el)} // Assign ref based on selection
            >
              <Trening
                momcad={training.selection}
                dan1={training.days[0]?.day}
                sati1={training.days[0]?.time}
                dan2={training.days[1]?.day}
                sati2={training.days[1]?.time}
                dan3={training.days[2]?.day}
                sati3={training.days[2]?.time}
                dan4={training.days[3]?.day}
                sati4={training.days[3]?.time}
                trener={training.trainerName}
                number={training.trainerContact}
              />
            </div>
          ))}
      </div>
    </>
  );
}

function Trening(props) {
  return (
    <div className="trening">
      <div className="trening-grid">
        <h2>{props.momcad}</h2>
        <div className="trening-grid-2">
          <div className="trening-box">
            <h4>{props.dan1}</h4>
            <p>{props.sati1}</p>
          </div>
          {props.dan2 && props.sati2 && (
            <div className="trening-box">
              <h4>{props.dan2}</h4>
              <p>{props.sati2}</p>
            </div>
          )}
          {props.dan3 && props.sati3 && (
            <div className="trening-box">
              <h4>{props.dan3}</h4>
              <p>{props.sati3}</p>
            </div>
          )}
          {props.dan4 && props.sati4 && (
            <div className="trening-box">
              <h4>{props.dan4}</h4>
              <p>{props.sati4}</p>
            </div>
          )}
        </div>
      </div>
      <div className="trening-info">
        <h5>TRENER: {props.trener}</h5>
        <a href={`tel:${props.number}`}>
          <i className="fi fi-rs-phone-flip"></i> {props.number}
        </a>
      </div>
    </div>
  );
}
