import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../../firebaseConfig"; // Import Firestore
import { doc, getDoc } from "firebase/firestore"; // Import getDoc function
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import NKPodsused from "../../../Assets/Images/Nk-Podsused-Logo.png";
import Main from "../Home/Main";

export default function NewsDetails() {
  const { slug } = useParams(); // Get the slug from the URL
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused"); // Reference to the specific document
        const docSnap = await getDoc(docRef);

        // Check if the document exists
        if (docSnap.exists()) {
          const data = docSnap.data();
          const novosti = data.novosti || []; // Access the novosti array

          // Find the specific news item that matches the slug
          const matchedNews = novosti.find((item) => item.slug === slug);

          if (matchedNews) {
            setNews(matchedNews); // Set the matched news item
          } else {
            setError("Novost nije pronađena!"); // Handle case where no document matches the slug
          }
        } else {
          setError("Dokument nije pronađen!"); // Handle case where the document does not exist
        }
      } catch (error) {
        setError("Došlo je do pogreške prilikom dohvaćanja novosti.");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsData();
    window.scrollTo(0, 0); // Scroll to top of page
  }, [slug]);

  const handleGoBack = () => {
    window.history.back();
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="NOVOSTI"
      />
      <div className="section">
        {/* Loading and Error States */}
        {loading && <div className="center">Učitavanje...</div>}{" "}
        {/* Loading state */}
        {error && (
          <div className="news-not-found">
            <img src={NKPodsused} alt="NK Podsused" loading="lazy" />
            <h2>{error}</h2>
            <Link to="/novosti" className="go-back-btn" onClick={handleGoBack}>
              Vrati se natrag
            </Link>
          </div>
        )}
        {!loading && !error && news && (
          <div className="novosti-open">
            <Link to="/novosti" className="go-back-btn" onClick={handleGoBack}>
              Vrati se natrag
            </Link>
            {news.mainImage && (
              <img
                className="novosti-open-img"
                src={news.mainImage}
                alt={news.imageAlt}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.newsAuthor && (
              <span className="img-author">{news.imageAuthor}</span>
            )}
            {news.subtitle && <p className="subtitle">{news.subtitle}</p>}
            {news.category && <h5>#{news.category}</h5>}
            {news.title && <h2>{news.title}</h2>}
            <div className="novosti-text">
              {news.introTexts &&
                news.introTexts.map((text, index) => <p key={index}>{text}</p>)}
            </div>
            {/* Prikaz sekcija ako ih ima */}
            {news.sections &&
              news.sections.map((section, index) => (
                <div key={index} className="novosti-text">
                  {section.subtitle && <h5>{section.subtitle}</h5>}{" "}
                  {/* Displaying subtitle for each section */}
                  {section.text && <p>{section.text}</p>}
                  {section.image && (
                    <img
                      src={section.image}
                      alt={section.imageAlt || "NK Podsused"}
                      onContextMenu={preventContextMenu}
                      style={{ userSelect: "none" }}
                      loading="lazy"
                    />
                  )}
                  {section.imageAuthor && (
                    <span className="img-author">{section.imageAuthor}</span>
                  )}
                </div>
              ))}
            <div className="novosti-text">
              {news.newsAuthor && <h5>{news.newsAuthor}</h5>}
            </div>
            {news.date && (
              <span>{new Date(news.date).toLocaleDateString("hr-HR")}</span>
            )}{" "}
          </div>
        )}
      </div>
    </>
  );
}
