import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";

// Images
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";

export default function Limaci() {
  const [coach, setCoach] = useState(null); // Holds the coach data
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  useEffect(() => {
    document.title = "NK Podsused | Limači";

    const PODSUSED_DOC_REF = doc(db, "podsused", "podsused");

    const fetchCoaches = async () => {
      try {
        const docSnap = await getDoc(PODSUSED_DOC_REF);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const treneri = data.treneri || [];

          // Rename variable to reflect Limači selection
          const limaciCoach = treneri.find(
            (coach) => coach.selection === "Limači"
          );

          if (limaciCoach) {
            setCoach(limaciCoach);
          } else {
            setError("Trener nije pronađen.");
          }
        } else {
          setError("Podaci nisu pronađeni.");
        }
      } catch (error) {
        setError("Došlo je do pogreške prilikom dohvaćanja trenera.");
      } finally {
        setLoading(false);
      }
    };

    fetchCoaches();
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* Main component for background images and title */}
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="LIMAČI"
      />

      <div className="seniori section">
        <div className="podsused-team">
          {/* TRENER SECTION */}
          <h3>TRENER</h3>
          <div className="team-grid">
            {loading && <p className="center">Učitavanje...</p>}{" "}
            {/* Show loading */}
            {error && <p className="center">{error}</p>} {/* Show error */}
            {!loading && !error && coach && (
              <Coach image={coach.image || NoPlayer} name={coach.name} />
            )}
          </div>
        </div>

        {/* Button for navigating to other matches */}
        <Link
          className="btn"
          to="/ostale-utakmice"
          onClick={() => handleClick("/")}
        >
          Utakmice &rarr;
        </Link>
      </div>
    </>
  );
}

// Separate Coach component to display the coach's image and name
function Coach({ image, name }) {
  return (
    <div className="player-box">
      <img src={image} alt={name} />
      <h3>{name}</h3>
    </div>
  );
}
