import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import NKPodsused from "../../../Assets/Images/Nk-Podsused-Logo.png";

export default function ShutdownPage() {
  const [isShutdown, setIsShutdown] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkShutdownStatus = async () => {
      try {
        const docRef = doc(db, "settings", "shutdown"); // Collection: 'settings', Document ID: 'shutdown'
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setIsShutdown(docSnap.data().shutdown); // Checking if the 'shutdown' field is true
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    checkShutdownStatus();
  }, []);

  if (loading) {
    return <div className="center">Učitavanje...</div>;
  }

  if (isShutdown) {
    return (
      <div className="shutdown-page">
        <img src={NKPodsused} alt="NK Podsused" />
        <h1 className="center">Web stranica trenutno nije dostupna!</h1>
        <h3>NK Podsused</h3>
      </div>
    );
  }

  return null;
}
