import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";

const StandingsTable = ({ standingsData, showAll }) => {
  let dataToDisplay = [];

  // Determine the index of "NK Podsused"
  const podsusedIndex = standingsData.findIndex(
    (team) => team.klub === "NK Podsused"
  );

  if (showAll) {
    dataToDisplay = standingsData;
  } else {
    if (podsusedIndex <= 4) {
      // If Podsused is in the top 5
      dataToDisplay = standingsData.slice(0, 5);
    } else if (podsusedIndex >= standingsData.length - 5) {
      // If Podsused is in the bottom 5
      dataToDisplay = standingsData.slice(-5);
    } else {
      // Podsused is in the middle
      const start = Math.max(podsusedIndex - 2, 0);
      dataToDisplay = standingsData.slice(start, start + 5);
    }
  }

  return (
    <ul className="table">
      <li className="row">
        <h5>#</h5>
        <h5>Klub</h5>
        <h5>Ut.</h5>
        <h5>G +/-</h5>
        <h5>Bod</h5>
      </li>
      {dataToDisplay.map((team, index) => {
        const rowClass = team.klub.includes("NK Podsused")
          ? "row podsused"
          : "row";

        // Prikazujemo točan rang u odnosu na cijeli niz
        const actualRank = standingsData.indexOf(team) + 1;

        return (
          <li key={team.id} className={rowClass}>
            <h5>{actualRank}</h5>{" "}
            {/* Koristimo actualRank za ispravnu poziciju */}
            <p>
              <img src={team.logoUrl} alt={`${team.klub} logo`} />
              {team.klub}
            </p>
            <p>{team.utk}</p>
            <p>{team.raz}</p>
            <p>{team.bod}</p>
          </li>
        );
      })}
    </ul>
  );
};

const Standings = () => {
  const [standingsData, setStandingsData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the document from the "podsused" collection
        const docRef = doc(db, "podsused", "podsused"); // Adjust as per your Firestore structure
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data().klubovi; // Assuming klubovi is the array of teams

          // Sort the standings data
          const sortedData = data.sort((a, b) => {
            if (parseInt(b.bod) !== parseInt(a.bod)) {
              return parseInt(b.bod) - parseInt(a.bod);
            }
            if (parseInt(b.raz) !== parseInt(a.raz)) {
              return parseInt(b.raz) - parseInt(a.raz);
            }
            return a.klub.localeCompare(b.klub);
          });

          setStandingsData(sortedData);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  // Toggle the display state
  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    <>
      <StandingsTable standingsData={standingsData} showAll={showAll} />
      <button className="standings-btn" onClick={toggleShowAll}>
        {showAll ? "Prikaži manje" : "Prikaži više"}
      </button>
    </>
  );
};

export default Standings;
