import React, { useEffect, useState } from "react";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Main from "../../Pages/Home/Main";
import { useNavigate } from "react-router-dom";

// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

const AdminMembers = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [videos, setVideos] = useState([]);

  // States za novosti
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [altText, setAltText] = useState("");
  const [introText, setIntroText] = useState("");
  const [author, setAuthor] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  // States za videozapise
  const [videoUrl, setVideoUrl] = useState("");
  const [videoSubtitle, setVideoSubtitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");

  const db = getFirestore();
  const storage = getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const članoviDocRef = doc(db, "podsused", "članovi");
      const docSnap = await getDoc(članoviDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setNewsItems(data.članoviNovosti || []);
        setVideos(data.video || []);
      }
    };

    fetchData();
  }, [db]);

  // Upload slike u Firebase Storage
  const handleImageUpload = async (file) => {
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.error("Error uploading image:", error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setImageUrl(downloadURL);
      }
    );
  };

  // Formatiraj datum u dd.mm.yyyy format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Kreiraj embed URL za Google Drive video
  const getGoogleDriveEmbedUrl = (url) => {
    const fileIdMatch = url.match(/\/d\/(.*?)\//);
    if (fileIdMatch && fileIdMatch[1]) {
      const fileId = fileIdMatch[1];
      return `https://drive.google.com/file/d/${fileId}/preview`;
    }
    alert("URL nije ispravan. Provjerite format URL-a i pokušajte ponovo.");
    return null;
  };

  // Dodaj novu novost
  const handleAddNews = async (e) => {
    e.preventDefault();

    if (!imageUrl) {
      alert("Pričekajte da se slika učita!");
      return;
    }

    const newNewsItem = {
      title,
      imageUrl,
      altText,
      introText,
      author,
      date: formatDate(date),
    };

    const članoviDocRef = doc(db, "podsused", "članovi");
    await updateDoc(članoviDocRef, {
      članoviNovosti: [...newsItems, newNewsItem],
    });

    setNewsItems((prevItems) => [...prevItems, newNewsItem]);
    resetForm();
  };

  // Dodaj novi video
  const handleAddVideo = async (e) => {
    e.preventDefault();

    const embedUrl = getGoogleDriveEmbedUrl(videoUrl);
    if (!embedUrl) return;

    const newVideoItem = {
      videoUrl: embedUrl,
      subtitle: videoSubtitle,
      description: videoDescription,
    };

    const članoviDocRef = doc(db, "podsused", "članovi");
    await updateDoc(članoviDocRef, {
      video: [...videos, newVideoItem],
    });

    setVideos((prevVideos) => [...prevVideos, newVideoItem]);
    resetForm();
  };

  // Resetiraj polja forme
  const resetForm = () => {
    setTitle("");
    setImageUrl("");
    setAltText("");
    setIntroText("");
    setAuthor("");
    setDate(new Date().toISOString().slice(0, 10));
    setVideoUrl("");
    setVideoSubtitle("");
    setVideoDescription("");
  };

  // Obriši novost
  const handleDeleteNews = async (index) => {
    const updatedNewsItems = [...newsItems];
    updatedNewsItems.splice(index, 1);

    const članoviDocRef = doc(db, "podsused", "članovi");
    await updateDoc(članoviDocRef, { članoviNovosti: updatedNewsItems });
    setNewsItems(updatedNewsItems);
  };

  // Obriši video
  const handleDeleteVideo = async (index) => {
    const updatedVideos = [...videos];
    updatedVideos.splice(index, 1);

    const članoviDocRef = doc(db, "podsused", "članovi");
    await updateDoc(članoviDocRef, { video: updatedVideos });
    setVideos(updatedVideos);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI ČLANOVE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/admin/dashboard");
          }}
        >
          Vrati se nazad
        </button>

        {/* Forma za dodavanje novosti */}
        <h2>Dodaj Novost</h2>
        <form className="form" onSubmit={handleAddNews}>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files[0])}
            required
          />
          <input
            type="text"
            placeholder="Opis slike"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Naslov"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Uvodni tekst"
            value={introText}
            onChange={(e) => setIntroText(e.target.value)}
          />
          <input
            type="text"
            placeholder="Autor"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <button className="add-btn" type="submit">
            Dodaj Novost
          </button>
        </form>

        {/* Forma za dodavanje videa */}
        <h2>Dodaj Video</h2>
        <form className="form" onSubmit={handleAddVideo}>
          <input
            type="text"
            placeholder="Google Drive URL"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Podnaslov"
            value={videoSubtitle}
            onChange={(e) => setVideoSubtitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Opis videa"
            value={videoDescription}
            onChange={(e) => setVideoDescription(e.target.value)}
            required
          />
          <button className="add-btn" type="submit">
            Dodaj Video
          </button>
        </form>

        {/* Prikaz novosti */}
        <h2>Novosti</h2>
        <div className="members-private">
          {newsItems.map((newsItem, index) => (
            <div className="members-news" key={index}>
              <h3>{newsItem.title}</h3>
              <img src={newsItem.imageUrl} alt={newsItem.altText} />
              <p>{newsItem.introText}</p>
              <p>Autor: {newsItem.author}</p>
              <p>Datum: {newsItem.date}</p>
              <button
                className="remove-btn"
                onClick={() => handleDeleteNews(index)}
              >
                Obriši
              </button>
            </div>
          ))}

          {/* Prikaz videa */}
          <h2>Video</h2>

          {videos.map((video, index) => (
            <div key={index} className="video">
              <iframe
                src={video.videoUrl}
                width="560"
                height="315"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={`Video ${index}`}
              ></iframe>
              <h4>{video.subtitle}</h4>
              <p>{video.description}</p>
              <button
                className="remove-btn"
                onClick={() => handleDeleteVideo(index)}
              >
                Obriši
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminMembers;
