import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import ClanskaIskaznica from "../../../Assets/Images/Ostalo/Članska-iskaznica-prednja.png";
import Main from "../Home/Main";
import { useEffect } from "react";

export default function Member() {
  useEffect(() => {
    document.title = "NK Podsused | Postani član";
  }, []);

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
      />
      <div className="section">
        <h2>POSTANI ČLAN</h2>
        <div className="member">
          <div className="member-box">
            <img
              src={ClanskaIskaznica}
              alt="Članska Iskaznica NK Podsused"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
          </div>
          <div className="member-box">
            <h5>Želiš postati član Nogometnog kluba Podsused?</h5>
            <h6>Pogodnosti članstva:</h6>
            <ul>
              <li>Članska iskaznica kao suvenir</li>
              <li>
                <span>10%</span> popusta našeg sponzora i proizvođača opreme
                TOTAL SPORT j.d.o.o. prilikom kupnje bilo kojeg asortimana
              </li>
              <li>
                <span>20%</span> na odabrani artikl, i <span>10%</span> na
                ukupnu kupnju u Hervis poslovnicama.
              </li>

              <li>
                Pristup videozapisa svih domaćih utakmica NK Podsuseda u{" "}
                <span>1080p</span> - USKORO!
              </li>
            </ul>
          </div>
        </div>
        <div className="member-price">
          <span>Cijena:</span>
          <h3>40€ / sezona</h3>
        </div>
        <div className="center">
          <a
            target="blank"
            className="btn"
            href="https://clanstvo.relago.hr/OnlineUpisi/Index?id=8def3b84-1391-4a6c-8f47-78360d0b2262"
          >
            Učlani se &rarr;
          </a>
        </div>
      </div>
    </>
  );
}
