import React, { useState } from "react";
import { auth } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import Main from "../Pages/Home/Main";

// Slike
import NKPodsusedBijelaBg from "../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

import { signInWithEmailAndPassword } from "firebase/auth"; // Importujte ovu funkciju

function MembersLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setMessage("Uspješna prijava kao član.");
        navigate("/članovi-kluba/naslovna");
      })
      .catch(() => setMessage("Pogrešni podaci. Molimo pokušajte ponovno."));
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="ČLANOVI KLUBA PRIJAVA"
      />
      <div className="section">
        <form className="login-box" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Lozinka"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="error-message">{message}</div>
          <button type="submit">Prijavi se</button>
        </form>
      </div>
    </>
  );
}

export default MembersLogin;
