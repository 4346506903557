import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const MembersAuthContext = createContext();

export const MembersAuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Početno stanje loadinga je true
  const [currentUser, setCurrentUser] = useState(null); // Dodano stanje za trenutnog korisnika
  const auth = getAuth();

  // Praćenje promjena u autentifikaciji
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setCurrentUser(user); // Postavi trenutnog korisnika
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null); // Ako korisnik nije prijavljen
      }
      setLoading(false); // Uvijek postavi loading na false nakon provjere
    });

    // Očistite pretplatu prilikom odjave
    return () => unsubscribe();
  }, [auth]);

  const login = async (email, password) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      console.error("Greška prilikom prijave: ", error);
      setIsAuthenticated(false);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    if (currentUser) {
      // Provjera da li je korisnik prijavljen
      await signOut(auth); // Poziv funkcije za odjavu
      setIsAuthenticated(false); // Postavljanje stanja na false
      setCurrentUser(null); // Resetiranje trenutnog korisnika
    }
  };

  return (
    <MembersAuthContext.Provider
      value={{ login, logout, isAuthenticated, loading, currentUser }}
    >
      {children}
    </MembersAuthContext.Provider>
  );
};

export const useMembersAuth = () => {
  return useContext(MembersAuthContext);
};
