import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db, storage } from "../../../firebaseConfig";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../../Pages/Home/Main";
import NewsPreview from "./NewsPreview";

const AddNews = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const newsItem = location.state?.newsItem;

  // State management
  const [mainImage, setMainImage] = useState(null);
  const [imageAlt, setImageAlt] = useState("");
  const [imageAuthor, setImageAuthor] = useState("");
  const [category, setCategory] = useState("Odaberite");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [introTexts, setIntroTexts] = useState([""]);
  const [sections, setSections] = useState([]);
  const [newsAuthor, setNewsAuthor] = useState("");
  const [date, setDate] = useState("");
  const [previewData, setPreviewData] = useState(null);
  const [errors, setErrors] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const [newsList, setNewsList] = useState([]);

  // Categories
  const categories = [
    "Odaberite",
    "Novosti sa utakmica",
    "Novosti",
    "Zanimljivosti",
    "Ostalo",
  ];

  // Slug generation function
  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "")
      .replace(/--+/g, "-")
      .replace(/^-|-$/g, "");
  };

  // Update slug whenever title changes
  useEffect(() => {
    setSlug(generateSlug(title));
  }, [title]);

  const addIntroText = () => {
    setIntroTexts([...introTexts, ""]);
  };

  const removeIntroText = (index) => {
    if (introTexts.length > 1) {
      const updatedIntroTexts = introTexts.filter((_, i) => i !== index);
      setIntroTexts(updatedIntroTexts);
    }
  };

  const addSection = () => {
    setSections([
      ...sections,
      { image: null, imageAlt: "", imageAuthor: "", subtitle: "", text: "" },
    ]);
  };

  const removeSection = (index) => {
    const updatedSections = sections.filter((_, i) => i !== index);
    setSections(updatedSections);
  };

  const handlePreview = () => {
    setPreviewData({
      title,
      slug,
      date,
      author: newsAuthor,
      mainImage,
      imageAlt,
      imageAuthor,
      category,
      introTexts,
      sections,
    });
  };

  const handleEdit = (item) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMainImage(item.mainImage);
    setImageAlt(item.imageAlt);
    setImageAuthor(item.imageAuthor);
    setCategory(item.category);
    setTitle(item.title);
    setSlug(generateSlug(item.title));
    setIntroTexts(item.introTexts);
    setSections(item.sections);
    setNewsAuthor(item.author);
    setDate(item.date);
  };
  const handleDelete = async (index) => {
    const confirmDelete = window.confirm(
      "Jeste li sigurni da želite obrisati?"
    );
    if (confirmDelete) {
      try {
        const updatedNewsList = [...newsList];
        updatedNewsList.splice(index, 1);

        const podsusedDocRef = doc(db, "podsused", "podsused");
        await updateDoc(podsusedDocRef, { novosti: updatedNewsList });

        setNewsList(updatedNewsList);
      } catch (error) {
        alert("Pogreška prilikom brisanja. Molim, pokušajte ponovo.");
      }
    }
  };

  // Function to upload an image
  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = ref(storage, `novosti/${file.name}-${Date.now()}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  // Function to publish or update the news
  const handlePublish = async (e) => {
    e.preventDefault();

    if (formDisabled) return;

    // Validation
    const validationErrors = {};
    if (!mainImage) validationErrors.mainImage = "Glavna slika je obavezna.";
    if (!imageAlt) validationErrors.imageAlt = "Opis glavne slike je obavezan.";
    if (!title) validationErrors.title = "Glavni naslov je obavezan.";
    if (category === "Odaberite")
      validationErrors.category = "Kategorija je obavezna.";

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setFormDisabled(true);

    try {
      const newNewsItem = {
        imageAlt,
        imageAuthor,
        category,
        title,
        slug,
        introTexts,
        sections,
        newsAuthor,
        date,
      };

      // Upload main image if it exists
      if (mainImage) {
        const mainImageURL = await uploadImage(mainImage);
        newNewsItem.mainImage = mainImageURL;
      }

      // Upload section images if they exist
      const updatedSections = await Promise.all(
        sections.map(async (section) => {
          if (section.image) {
            const sectionImageURL = await uploadImage(section.image);
            return { ...section, image: sectionImageURL };
          }
          return section;
        })
      );
      newNewsItem.sections = updatedSections;

      // Fetch the existing document to update the novosti array
      const podsusedDocRef = doc(db, "podsused", "podsused");
      const podsusedDoc = await getDoc(podsusedDocRef);

      if (podsusedDoc.exists()) {
        // Document exists, update the novosti array
        await updateDoc(podsusedDocRef, {
          novosti: [...podsusedDoc.data().novosti, newNewsItem],
        });
      } else {
        // Document does not exist, create it with the novosti array
        await updateDoc(podsusedDocRef, {
          novosti: [newNewsItem],
        });
      }

      resetForm();
      navigate("/admin/dashboard");
    } catch (error) {
      console.error("Error publishing news: ", error);
      alert("An error occurred while publishing. Please try again.");
    } finally {
      setFormDisabled(false);
    }
  };

  const resetForm = () => {
    setMainImage(null);
    setImageAlt("");
    setImageAuthor("");
    setCategory("Odaberite");
    setTitle("");
    setSlug("");
    setIntroTexts([""]);
    setSections([]);
    setNewsAuthor("");
    setDate("");
    setErrors({});
  };

  useEffect(() => {
    const fetchNewsList = async () => {
      const podsusedDocRef = doc(db, "podsused", "podsused");
      const podsusedDoc = await getDoc(podsusedDocRef);
      if (podsusedDoc.exists()) {
        setNewsList(podsusedDoc.data().novosti || []);
      }
    };

    fetchNewsList();
    if (newsItem) {
      setMainImage(newsItem.mainImage);
      setImageAlt(newsItem.imageAlt);
      setImageAuthor(newsItem.imageAuthor);
      setCategory(newsItem.category);
      setTitle(newsItem.title);
      setSlug(generateSlug(newsItem.title));
      setIntroTexts(newsItem.introTexts);
      setSections(newsItem.sections);
      setNewsAuthor(newsItem.author);
      setDate(newsItem.date);
    }
  }, [newsItem]);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />

      <div className={`section ${formDisabled ? "disabled" : ""}`}>
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <h2>{newsItem ? "Uredi Novost" : "Dodaj Novost"}</h2>

        <div className="news-form">
          <label>Glavna slika:</label>
          <input
            className={`news-img ${errors.mainImage ? "error" : ""}`}
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                setMainImage(file);
                setErrors({ ...errors, mainImage: "" }); // Clear error if file is selected
              }
            }}
          />
          {errors.mainImage && (
            <span className="error-message">{errors.mainImage}</span>
          )}
          <div className="news-img-form">
            <input
              type="text"
              placeholder="Opis slike (alt)"
              value={imageAlt}
              onChange={(e) => {
                setImageAlt(e.target.value);
                setErrors({ ...errors, imageAlt: "" }); // Clear error if text is entered
              }}
              className={errors.imageAlt ? "error" : ""}
            />

            <input
              type="text"
              placeholder="Autor slike"
              value={imageAuthor}
              onChange={(e) => {
                setImageAuthor(e.target.value);
              }}
            />
          </div>
          {errors.imageAlt && (
            <span className="error-message">{errors.imageAlt}</span>
          )}
        </div>

        <div className="news-form">
          <label>Kategorija:</label>
          <select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
              if (e.target.value !== "Odaberite") {
                setErrors({ ...errors, category: "" }); // Clear error if category is selected
              }
            }}
          >
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
          {errors.category && (
            <span className="error-message">{errors.category}</span>
          )}
        </div>

        <div className="news-form">
          <label>Glavni naslov:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              if (e.target.value) {
                setErrors({ ...errors, title: "" }); // Clear error if text is entered
              }
            }}
            placeholder="Glavni naslov"
            className={errors.title ? "error" : ""}
          />
          {errors.title && (
            <span className="error-message">{errors.title}</span>
          )}
        </div>

        <div className="news-form">
          {introTexts.map((text, index) => (
            <div key={index}>
              <input
                type="text"
                value={text}
                placeholder="Uvodni tekst"
                onChange={(e) => {
                  const updatedIntroTexts = [...introTexts];
                  updatedIntroTexts[index] = e.target.value;
                  setIntroTexts(updatedIntroTexts);
                }}
              />
              {index > 0 && (
                <button
                  className="news-btn remove-btn"
                  onClick={() => removeIntroText(index)}
                >
                  Obriši
                </button>
              )}
            </div>
          ))}
          <button className="news-btn" onClick={addIntroText}>
            Dodaj uvodni tekst
          </button>
        </div>

        <div className="news-form">
          {sections.map((section, index) => (
            <div key={index}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const updatedSections = [...sections];
                  const file = e.target.files[0];
                  if (file) {
                    updatedSections[index].image = file;
                    setSections(updatedSections);
                  }
                }}
              />
              <div className="alt-input">
                <input
                  type="text"
                  placeholder="Opis slike"
                  value={section.imageAlt}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[index].imageAlt = e.target.value;
                    setSections(updatedSections);
                  }}
                />
                <input
                  type="text"
                  placeholder="Autor slike"
                  value={section.imageAuthor}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[index].imageAuthor = e.target.value;
                    setSections(updatedSections);
                  }}
                />
              </div>
              <input
                type="text"
                placeholder="Podnaslov"
                value={section.subtitle}
                onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[index].subtitle = e.target.value;
                  setSections(updatedSections);
                }}
              />
              <textarea
                placeholder="Tekst sekcije"
                value={section.text}
                onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[index].text = e.target.value;
                  setSections(updatedSections);
                }}
              />
              <button
                className="news-btn remove-btn"
                onClick={() => removeSection(index)}
              >
                Obriši sekciju
              </button>
            </div>
          ))}
          <button className="news-btn" onClick={addSection}>
            Dodaj sekciju
          </button>
        </div>

        <div className="news-form">
          <label>Autor:</label>
          <input
            type="text"
            value={newsAuthor}
            onChange={(e) => setNewsAuthor(e.target.value)}
            placeholder="Autor"
          />
        </div>

        <div className="news-form">
          <label>Datum:</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            onClick={(e) => {
              e.target.showPicker(); // Otvara kalendar na klik
            }}
          />
        </div>

        <div className="news-form">
          <button className="news-btn" onClick={handlePreview}>
            Pregled
          </button>
          <button
            className="news-btn publish"
            onClick={handlePublish}
            disabled={formDisabled} // Disable button when form is disabled
          >
            Objavi
          </button>
        </div>
      </div>

      {previewData && (
        <NewsPreview article={previewData} onPublish={handlePublish} />
      )}

      <div className="section">
        <div className="news-list">
          <h3>Popis Novosti</h3>
          {newsList.map((newsItem, index) => (
            <div key={index} className="news-item">
              <img src={newsItem.mainImage} alt={newsItem.imageAlt} />
              <div>
                {" "}
                <span>#{newsItem.category}</span> <h3>{newsItem.title}</h3>
              </div>
              <div>
                {" "}
                <button
                  className="add-btn"
                  onClick={() => handleEdit(newsItem)}
                >
                  Uredi
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleDelete(index)}
                >
                  Obriši
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Event listener to close the form on click outside */}
      <div
        onClick={() => setFormDisabled(false)} // Enable form on click
        style={{
          display: formDisabled ? "block" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />
    </>
  );
};

export default AddNews;
