import React, { useState, useEffect } from "react";
import Background from "../../../Assets/Images/Ostalo/Plava-bg.jpg";
import NKPodsused from "../../../Assets/Images/Ostalo/NK-Podsused-BijeliLogo.png";

export default function LoadingScreen({ setLoadingComplete }) {
  const [displayLoading, setDisplayLoading] = useState(true);

  useEffect(() => {
    // Check if it's the first time the app is loaded
    const isFirstTimeLoad = localStorage.getItem("isFirstTimeLoad");

    if (!isFirstTimeLoad) {
      // If it's the first time, set a flag indicating that the app has been loaded
      localStorage.setItem("isFirstTimeLoad", true);
    } else {
      // If it's not the first time, don't display the loading screen
      setDisplayLoading(false);
      setLoadingComplete();
    }

    const loadingTimeout = setTimeout(() => {
      setDisplayLoading(false);
      setLoadingComplete();
    }, 1600);
    return () => clearTimeout(loadingTimeout);
  }, [setLoadingComplete]);

  return (
    <>
      <div
        style={{ display: displayLoading ? "block" : "none" }}
        className="loading-page"
      >
        <img src={Background} alt="NK Podsused" />
        <div className="loading-main">
          <img src={NKPodsused} alt="NK Podsused Logo" />
          <div className="loading-container">
            <div className="box box1"></div>
            <div className="box box2"></div>
            <div className="box box3"></div>
            <div className="box box4"></div>
            <div className="box box5"></div>
          </div>
        </div>
      </div>
    </>
  );
}
