import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateDoc,
  doc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Kategorije selekcija
const categories = [
  { label: "Veterani", value: "Veterani" },
  { label: "Seniori", value: "Seniori" },
  { label: "Kadeti 2.ZNL", value: "Kadeti 2.ZNL" },
  { label: "Kadeti 1.ZNL", value: "Kadeti 1.ZNL" },
  { label: "Pioniri 3.ZNL", value: "Pioniri 3.ZNL" },
  { label: "Pioniri 1.ZNL", value: "Pioniri 1.ZNL" },
  { label: "Mlađi Pioniri U12/U13", value: "Mlađi Pioniri" },
  { label: "Limači U10/U11", value: "Limači" },
  { label: "Zagići U08/U09", value: "Zagići" },
];

// Define the category order
const categoryOrder = [
  "Veterani",
  "Seniori",
  "Kadeti 1.ZNL",
  "Kadeti 2.ZNL",
  "Pioniri 1.ZNL",
  "Pioniri 3.ZNL",
  "Mlađi Pioniri",
  "Limači",
  "Zagići",
];

export default function AddSelections() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [result, setResult] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [existingMatchId, setExistingMatchId] = useState(null);
  const [matches, setMatches] = useState([]);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const timeInputRef = useRef(null);
  const dateInputRef = useRef(null);

  const handleCategorySelect = (event) => {
    setSelectedCategory(event.target.value);
    clearFields();
  };

  const clearFields = () => {
    setHomeTeam("");
    setAwayTeam("");
    setResult("");
    setTime("");
    setDate("");
    setLocation("");
    setExistingMatchId(null);
    setMessage(""); // Clear message
  };

  // Fetch existing matches from document podsused
  const fetchMatches = async () => {
    try {
      const docRef = doc(db, "podsused", "podsused");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const matchList = docSnap.data().ostaleUtakmice || [];
        setMatches(matchList); // Spremiti utakmice u state
      } else {
        setMessage("Dokument ne postoji.");
      }
    } catch (error) {
      setMessage("Greška pri učitavanju utakmica.");
    }
  };

  useEffect(() => {
    fetchMatches(); // Load matches when the component mounts
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!homeTeam || !awayTeam || !result || !time || !date || !location) {
      setMessage("Molimo unesite sve podatke!");
      return;
    }

    const podsusedRef = doc(db, "podsused", "podsused"); // Dokument podsused

    try {
      const matchData = {
        id: existingMatchId || new Date().getTime(), // Unique ID for new match
        category: selectedCategory,
        homeTeam,
        awayTeam,
        result,
        time,
        date: formatDate(date), // Format the date as dd.mm.yyyy before sending to Firestore
        location,
      };

      if (existingMatchId) {
        // Update existing match
        const oldMatch = matches.find((m) => m.id === existingMatchId);

        // Remove old match
        await updateDoc(podsusedRef, {
          ostaleUtakmice: arrayRemove(oldMatch),
        });

        // Add new updated match
        await updateDoc(podsusedRef, {
          ostaleUtakmice: arrayUnion(matchData),
        });

        setMessage("Utakmica uspješno ažurirana!");
      } else {
        // Add new match
        await updateDoc(podsusedRef, {
          ostaleUtakmice: arrayUnion(matchData),
        });

        setMessage("Utakmica uspješno dodana!");
      }

      clearFields();
      fetchMatches(); // Reload matches after adding/updating
    } catch (error) {
      setMessage("Greška prilikom dodavanja/ ažuriranja utakmice.");
    }
  };

  const handleCancel = () => {
    clearFields();
    setSelectedCategory("");
  };

  const openTimePicker = () => {
    if (timeInputRef.current) {
      timeInputRef.current.focus();
    }
  };

  const openDatePicker = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
    }
  };

  const handleDelete = async (matchId) => {
    try {
      const matchToDelete = matches.find((match) => match.id === matchId);

      const podsusedRef = doc(db, "podsused", "podsused");

      // Ukloni utakmicu iz niza ostaleUtakmice
      await updateDoc(podsusedRef, {
        ostaleUtakmice: arrayRemove(matchToDelete),
      });

      setMessage("Utakmica uspješno obrisana.");
      fetchMatches(); // Ponovno učitaj utakmice nakon brisanja
    } catch (error) {
      setMessage("Greška pri brisanju utakmice.");
    }
  };

  const handleEdit = (match) => {
    setHomeTeam(match.homeTeam);
    setAwayTeam(match.awayTeam);
    setResult(match.result);
    setTime(match.time);
    setDate(reverseFormatDate(match.date));
    setLocation(match.location);
    setSelectedCategory(match.category);
    setExistingMatchId(match.id);
  };

  const formatDate = (inputDate) => {
    const [year, month, day] = inputDate.split("-");
    return `${day}.${month}.${year}`;
  };

  const reverseFormatDate = (formattedDate) => {
    const [day, month, year] = formattedDate.split(".");
    return `${year}-${month}-${day}`;
  };

  // Sort matches based on category order
  const sortedMatches = matches.sort((a, b) => {
    return (
      categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
    );
  });

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DODAJ UTAKMICE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/admin/dashboard");
          }}
        >
          Vrati se nazad
        </button>
        <div className="form">
          <select value={selectedCategory} onChange={handleCategorySelect}>
            <option value="" disabled>
              Odaberite selekciju
            </option>
            {categories.map((cat) => (
              <option key={cat.value} value={cat.value}>
                {cat.label}
              </option>
            ))}
          </select>
        </div>

        {selectedCategory && (
          <form className="addMatches-form" onSubmit={handleSubmit}>
            <h3>Dodaj utakmicu za {selectedCategory}</h3>
            <div className="club-flex">
              <input
                type="text"
                placeholder="Domaći Klub"
                value={homeTeam}
                onChange={(e) => setHomeTeam(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Gostujući Klub"
                value={awayTeam}
                onChange={(e) => setAwayTeam(e.target.value)}
                required
              />
            </div>
            <input
              type="text"
              placeholder="Rezultat (npr. 2:1)"
              value={result}
              onChange={(e) => setResult(e.target.value)}
              required
            />
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              onClick={openTimePicker}
              ref={timeInputRef}
              required
            />
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              onClick={openDatePicker}
              ref={dateInputRef}
              required
            />
            <input
              type="text"
              placeholder="Lokacija"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
            <div className="button-group">
              <button type="submit" className="add-btn">
                {existingMatchId ? "Ažuriraj" : "Dodaj"} Utakmicu
              </button>
              <button
                type="button"
                className="remove-btn"
                onClick={handleCancel}
              >
                Otkaži
              </button>
            </div>
          </form>
        )}

        {message && <p className="message">{message}</p>}

        <h3 className="center">Popis utakmica:</h3>
        <ul className="matches-list">
          {sortedMatches.map((match) => (
            <li key={match.id} className="match-info">
              <h3 className="selection-category">{match.category}</h3>
              <div className="match-result">
                <div className="team-info">
                  <h3 className="team-name">{match.homeTeam}</h3>
                </div>
                <div className="final-result">
                  <h2>{match.result}</h2>
                </div>
                <div className="team-info">
                  <h3 className="team-name">{match.awayTeam}</h3>
                </div>
              </div>
              <div>
                <h5>
                  <i className="fi fi-rs-calendar-days"></i>
                  {match.date} - {match.time}
                </h5>
                <h5>
                  <i className="fi fi-rs-marker"></i>
                  {match.location}
                </h5>
              </div>
              <button className="add-btn" onClick={() => handleEdit(match)}>
                Uredi
              </button>
              <button
                className="remove-btn"
                onClick={() => handleDelete(match.id)}
              >
                Obriši
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
