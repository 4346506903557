import React, { useState, useEffect } from "react";
import { db, storage } from "../../../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import Main from "../../Pages/Home/Main";
import { useNavigate } from "react-router-dom";

// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function AddTrenere() {
  const [coaches, setCoaches] = useState([]);
  const [name, setName] = useState("");
  const [selection, setSelection] = useState(""); // Selekcija tima
  const [image, setImage] = useState(null);
  const [editId, setEditId] = useState(null); // Za uređivanje
  const navigate = useNavigate();

  const PODSUSED_DOC_REF = doc(db, "podsused", "podsused");

  // Učitavanje trenera iz Firestore
  useEffect(() => {
    const PODSUSED_DOC_REF = doc(db, "podsused", "podsused"); // Move this inside useEffect

    const fetchCoaches = async () => {
      const docSnap = await getDoc(PODSUSED_DOC_REF);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setCoaches(data.treneri || []);
      }
    };

    fetchCoaches();
  }, []);

  // Funkcija za upload slike u Firebase Storage
  const handleImageUpload = async (file) => {
    if (!file) {
      return NoPlayer; // Ako nema slike, postavi default sliku
    }
    const storageRef = ref(storage, `treneri/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(resolve);
        }
      );
    });
  };

  // Dodavanje ili ažuriranje trenera
  const handleAddOrUpdateCoach = async () => {
    const coachData = {
      name,
      selection,
      image: await handleImageUpload(image),
    };

    let updatedCoaches = [];

    if (editId !== null) {
      // Ažuriraj postojećeg trenera
      updatedCoaches = coaches.map((coach) =>
        coach.id === editId ? { ...coach, ...coachData } : coach
      );
    } else {
      // Dodaj novog trenera
      updatedCoaches = [...coaches, { ...coachData, id: new Date().getTime() }];
    }

    // Update Firestore document with the new/updated array
    await updateDoc(PODSUSED_DOC_REF, { treneri: updatedCoaches });

    // Resetuj inpute
    setName("");
    setSelection("");
    setImage(null);
    setEditId(null);
    setCoaches(updatedCoaches);
  };

  // Uređivanje trenera
  const handleEditCoach = (coach) => {
    setName(coach.name);
    setSelection(coach.selection);
    setEditId(coach.id);
  };

  // Brisanje trenera
  const handleDeleteCoach = async (id) => {
    const updatedCoaches = coaches.filter((coach) => coach.id !== id);

    // Update Firestore document
    await updateDoc(PODSUSED_DOC_REF, { treneri: updatedCoaches });

    setCoaches(updatedCoaches);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI TRENERE"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <h2>{editId ? "Uredi Trenera" : "Dodaj Trenera"}</h2>

        <div className="form">
          <label>Ime i Prezime:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Unesi ime i prezime"
          />

          <label>Selekcija:</label>
          <select
            value={selection}
            onChange={(e) => setSelection(e.target.value)}
          >
            <option value="">Odaberi selekciju</option>
            <option value="Seniori">Seniori</option>
            <option value="Kadeti">Kadeti U16/U17</option>
            <option value="Pioniri">Pioniri U14/U15</option>
            <option value="Mlađi pioniri">Mlađi Pioniri U12/U13</option>
            <option value="Limači">Limači U10/U11</option>
            <option value="Zagići">Zagići 08/U09</option>
          </select>

          <label>Slika:</label>
          <input type="file" onChange={(e) => setImage(e.target.files[0])} />

          <button className="add-btn" onClick={handleAddOrUpdateCoach}>
            {editId ? "Uredi" : "Dodaj"} Trenera
          </button>
        </div>

        {/* Prikaz liste trenera */}
        <h3 className="center">Lista Trenera</h3>
        <div className="podsused-team add-seniors">
          <div className="team-grid">
            {coaches.map((coach) => (
              <div key={coach.id} className="player-item">
                <img
                  src={coach.image || NoPlayer}
                  alt={coach.name}
                  style={{ width: "100px", height: "100px" }}
                />
                <div>
                  <h4>{coach.name}</h4>
                  <p>
                    <span>Selekcija:</span> {coach.selection}
                  </p>
                  <button
                    className="add-btn"
                    onClick={() => handleEditCoach(coach)}
                  >
                    Uredi
                  </button>
                  <button
                    className="remove-btn"
                    onClick={() => handleDeleteCoach(coach.id)}
                  >
                    Obriši
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
