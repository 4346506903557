import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import PlavaBG from "../../../Assets/Images/Ostalo/Plava-bg.jpg";
import { Link } from "react-router-dom";
import Main from "../../Pages/Home/Main";
import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";

export default function AdminDashboard() {
  const [isShutdown, setIsShutdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const isAdmin = true;

  useEffect(() => {
    const fetchShutdownStatus = async () => {
      const docRef = doc(db, "settings", "shutdown");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIsShutdown(docSnap.data().shutdown);
      }
      setLoading(false); // Finished loading
    };

    fetchShutdownStatus();
  }, []);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="ADMIN"
      />

      {/* Prikaz loading-a ispod <Main> */}
      {loading ? (
        <div className="section center">Loading...</div> // Show loading until the status is fetched
      ) : (
        <div className="section">
          {isShutdown && !isAdmin ? ( // If shutdown is active and user is not admin
            <div className="shutdown-message">
              <h1>NK Podsused web stranica trenutno nije dostupna!</h1>
            </div>
          ) : (
            <div className="home-grid">
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/dodaj-novost"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  DODAJ "NOVOST"
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/dodaj-utakmice-seniori"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  DODAJ UTAKMICE SENIORA
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/dodaj-ostale-utakmice"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  DODAJ OSTALE UTAKMICE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-seniore"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI SENIORE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-kadete"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI KADETE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-veterane"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI VETERANE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-trenere"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI TRENERE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-galeriju"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI GALERIJU
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/dodaj-strijelce"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  DODAJ STRIJELCE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-tablicu"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI TABLICU
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-treninge"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI TRENINGE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link
                  to="/admin/uredi-članove"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  UREDI ČLANOVE
                </Link>
              </div>
              <div className="home-btn">
                <img src={PlavaBG} alt="NK Podsued" />
                <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                  NASLOVNA
                </Link>
              </div>
              <ShutDownPageBtn />
            </div>
          )}
        </div>
      )}
    </>
  );
}

function ShutDownPageBtn() {
  const [isShutdown, setIsShutdown] = useState(false);

  useEffect(() => {
    const fetchShutdownStatus = async () => {
      const docRef = doc(db, "settings", "shutdown");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIsShutdown(docSnap.data().shutdown);
      }
    };

    fetchShutdownStatus();
  }, []);

  const handleToggleShutdown = async () => {
    const newStatus = !isShutdown;
    setIsShutdown(newStatus);

    const docRef = doc(db, "settings", "shutdown");
    await updateDoc(docRef, { shutdown: newStatus });
  };

  return (
    <div>
      <button className="add-btn center" onClick={handleToggleShutdown}>
        {isShutdown ? "Turn Off Shutdown" : "Turn On Shutdown"}
      </button>
    </div>
  );
}
