import { Link } from "react-router-dom";
import NKPodsused from "../../../Assets/Images/Nk-Podsused-Logo.png";

export default function Footer() {
  let year = new Date().getFullYear();

  // Scroll to top of the page
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <footer>
      <div className="footer-top">
        {" "}
        <img src={NKPodsused} alt="NK Podsused" />
        <div className="footer-container">
          <h3>KLUB</h3>
          <ul>
            <li>
              <Link
                className="footer-links"
                to="/o-klubu"
                onClick={() => handleClick("/")}
              >
                O KLUBU
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/seniori"
                onClick={() => handleClick("/")}
              >
                SENIORI
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/postani-član"
                onClick={() => handleClick("/")}
              >
                POSTANI ČLAN
              </Link>
            </li>

            <li>
              <Link
                className="footer-links"
                to="https://clanstvo.relago.hr/OnlineUpisi/Index?id=8def3b84-1391-4a6c-8f47-78360d0b2262"
                target="blank"
              >
                POSTANI NOVI IGRAČ
              </Link>
            </li>

            <li>
              <Link
                className="footer-links"
                to="/raspored-treninga"
                onClick={() => handleClick("/")}
              >
                RASPORED TRENINGA
              </Link>
            </li>

            <li>
              <Link
                className="footer-links"
                to="/galerija"
                onClick={() => handleClick("/")}
              >
                {" "}
                GALERIJA
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/novosti"
                onClick={() => handleClick("/")}
              >
                {" "}
                NOVOSTI
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/oprema"
                onClick={() => handleClick("/")}
              >
                OPREMA
              </Link>
            </li>
            {/*    <li>
              <Link
                className="footer-links"
                to="/donacije"
                onClick={() => handleClick("/")}
              >
                DONACIJE
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="footer-container">
          <h3>OSTALO</h3>
          <ul>
            <li>
              <Link
                className="footer-links"
                to="https://maps.app.goo.gl/67Kd1onNPYh3ZJxu6"
              >
                <i className="fi fi-rs-marker"></i>Kupališni put 1, 10000,
                Zagreb
              </Link>
            </li>
            <li>
              <a href="mailto:podsused.nk@gmail.com" className="footer-links">
                <i className="fi fi-rs-at"></i>podsused.nk@gmail.com
              </a>
            </li>
            <li>
              <a
                href="tel:+385 098 738 506"
                className="footer-links"
                to="podsused.nk@gmail.com"
              >
                <i className="fi fi-rs-phone-flip"></i> +385 098 738 506
              </a>
            </li>
            <li>
              <span className="footer-links">
                <i className="fi fi-rs-bank"></i>IBAN: HR8124070001100670399
              </span>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/politika-privatnosti"
                onClick={() => handleClick("/")}
              >
                Politika Privatnosti
              </Link>
            </li>
            <li>
              <Link
                className="footer-links"
                to="/uvjeti-korištenja"
                onClick={() => handleClick("/")}
              >
                Uvjeti Korištenja
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-container">
        <Link
          className="footer-social-links"
          to="https://www.facebook.com/nogometniklubPodsused/?locale=hr_HR"
          target="_blank"
        >
          <i className="fi fi-brands-facebook"></i>
        </Link>
        <Link
          className="footer-social-links"
          to="https://www.instagram.com/nkpodsused/"
          target="_blank"
        >
          <i className="fi fi-brands-instagram"></i>
        </Link>
      </div>
      <div className="footer-bottom">
        <h6>
          &copy; <span>{year}</span> NK Podsused
        </h6>
        <h5>Web izrada: Teo Ivanuš</h5>
      </div>
    </footer>
  );
}
