import React, { useState, useEffect } from "react";
import { storage, db } from "../../../firebaseConfig";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import { useNavigate } from "react-router-dom";

export default function AddGallery({ onImageAdded }) {
  const [images, setImages] = useState([]);
  const [author, setAuthor] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [gallery, setGallery] = useState([]); // State to store the gallery images
  const [editIndex, setEditIndex] = useState(null); // State to track if editing
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the gallery images on component mount
    const fetchGallery = async () => {
      const docRef = doc(db, "podsused", "podsused");
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        setGallery(docSnapshot.data().galerija || []);
      }
    };

    fetchGallery();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files) {
      setImages(Array.from(e.target.files));
    }
  };

  const handleUpload = async () => {
    if (images.length === 0 || !author) {
      setWarningMessage("Molim odaberite slike ili upišite autora.");
      setTimeout(() => {
        setWarningMessage("");
      }, 3000);
      return;
    }

    setUploading(true);
    setProgress(0);

    const docRef = doc(db, "podsused", "podsused");
    let currentGallery = gallery.slice(); // Make a copy of the current gallery

    const uploadPromises = images.map((image) => {
      const storageRef = ref(storage, `galerija/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progressPercent =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progressPercent);
          },
          (error) => {
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            if (editIndex !== null) {
              // Editing existing image
              currentGallery[editIndex] = { imageUrl: downloadURL, author };
              setEditIndex(null);
            } else {
              // Adding new image
              currentGallery.push({ imageUrl: downloadURL, author });
            }
            resolve();
          }
        );
      });
    });

    try {
      await Promise.all(uploadPromises);
      await updateDoc(docRef, { galerija: currentGallery });
      setGallery(currentGallery);
      setImages([]);
      setAuthor("");
      setProgress(0);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      if (onImageAdded) onImageAdded();
    } catch (error) {
      console.error("Error uploading images:", error);
      setWarningMessage("Došlo je do greške prilikom učitavanja slika.");
      setTimeout(() => {
        setWarningMessage("");
      }, 3000);
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (index) => {
    const imageToDelete = gallery[index];
    const storageRef = ref(storage, imageToDelete.imageUrl);

    try {
      // Delete the image from storage
      await deleteObject(storageRef);

      // Remove the image from the gallery array and update Firestore
      const updatedGallery = gallery.filter((_, i) => i !== index);
      const docRef = doc(db, "podsused", "podsused");
      await updateDoc(docRef, { galerija: updatedGallery });

      setGallery(updatedGallery);
    } catch (error) {
      console.error("Error deleting image:", error);
      setWarningMessage("Došlo je do greške prilikom brisanja slike.");
      setTimeout(() => {
        setWarningMessage("");
      }, 3000);
    }
  };

  const handleEdit = (index) => {
    const imageToEdit = gallery[index];
    setAuthor(imageToEdit.author);
    setEditIndex(index);
    window.scrollTo(0, 0); // Scroll to top to show the form for editing
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DODAJ GALERIJU"
      />
      <div className="section">
        <button
          className="go-back-btn"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/admin/dashboard");
          }}
        >
          Vrati se nazad
        </button>
        <h2>Dodaj novu sliku u galeriju</h2>
        <div className="form">
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            multiple
          />
          {warningMessage && (
            <div className="warning-message">{warningMessage}</div>
          )}
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="Autor slike"
          />
          {showSuccessMessage && (
            <div className="success-message">Slika objavljena!</div>
          )}
          <button
            className="add-btn"
            onClick={handleUpload}
            disabled={uploading}
          >
            {uploading ? `Učitavanje (${Math.round(progress)}%)` : "Objavi"}
          </button>
        </div>

        {/* Display the gallery images */}
        <div className="gallery-list">
          <h3>Galerija</h3>
          {gallery.length === 0 ? (
            <p>Nema dodanih slika.</p>
          ) : (
            gallery.map((image, index) => (
              <div key={index} className="gallery-item">
                <img
                  src={image.imageUrl}
                  alt={`Galerija ${index}`}
                  className="gallery-image"
                />
                <p>
                  <span>Autor:</span> {image.author}
                </p>
                <div>
                  <button className="add-btn" onClick={() => handleEdit(index)}>
                    Uredi
                  </button>
                  <button
                    className="remove-btn"
                    onClick={() => handleDelete(index)}
                  >
                    Obriši
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
}
