import React, { useEffect, useState } from "react";
import { db } from "../../../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore"; // Import za query
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import Main from "../../Pages/Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Seniors() {
  const [players, setPlayers] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [showImage, setShowImage] = useState(null);
  const [playerInfo, setPlayerInfo] = useState({});

  // Učitavanje postojećih igrača i trenera iz Firestore
  useEffect(() => {
    document.title = "NK Podsused | Veterani";
    const fetchPlayersAndCoaches = async () => {
      // Dohvat igrača
      const playersSnapshot = await getDocs(collection(db, "urediVeterane"));
      const playersData = playersSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPlayers(playersData);

      const coachesQuery = query(
        collection(db, "treneri"),
        where("selection", "==", "Veterani") // Filtriranje prema selekciji
      );
      const coachesSnapshot = await getDocs(coachesQuery);
      const coachesData = coachesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCoaches(coachesData);
    };

    fetchPlayersAndCoaches();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  const openImage = (image, info) => {
    setShowImage(image);
    setPlayerInfo(info);
  };

  const closeImage = () => {
    setShowImage(null);
    setPlayerInfo({});
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="VETERANI"
      />

      <div className="section">
        {/* Overlay za prikaz slike igrača */}
        {showImage && (
          <div className="overlay overlay-player" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <img
                id="popupImage"
                src={showImage}
                style={{ width: "100%", height: "100%", userSelect: "none" }}
                onContextMenu={preventContextMenu}
                alt="Show"
              />
              <div className="player-info">
                <h5>Datum rođenja:</h5>
                <p>{formatDate(playerInfo.birth)}</p>
                <h5>Pozicija:</h5>
                <p>{playerInfo.position}</p>
                <h5>Jača noga:</h5>
                <p>{playerInfo.foot}</p>
              </div>
            </div>
          </div>
        )}

        {/* *****NAPADAČI***** */}
        <h3 className="center">Napadači</h3>
        {players.filter(
          (player) =>
            player.position === "Napadač" ||
            player.position === "Lijevo krilo" ||
            player.position === "Desno krilo"
        ).length === 0 ? (
          <div className="info-box">Nema napadača.</div>
        ) : (
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Napadač" ||
                  player.position === "Lijevo krilo" ||
                  player.position === "Desno krilo"
              )
              .sort((a, b) => Number(a.number) - Number(b.number))
              .map((player) => (
                <Players
                  key={player.id}
                  image={player.image || NoPlayer}
                  name={player.name}
                  number={player.number}
                  info={{
                    birth: player.birth,
                    position: player.position,
                    foot: player.foot,
                  }}
                  openImage={openImage}
                />
              ))}
          </div>
        )}

        {/* *****VEZNI IGRAČI***** */}
        <h3 className="center">Vezni igrači</h3>
        {players.filter((player) => player.position === "Vezni").length ===
        0 ? (
          <div className="info-box">Nema veznih igrača.</div>
        ) : (
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Vezni")
              .sort((a, b) => Number(a.number) - Number(b.number))
              .map((player) => (
                <Players
                  key={player.id}
                  image={player.image || NoPlayer}
                  name={player.name}
                  number={player.number}
                  info={{
                    birth: player.birth,
                    position: player.position,
                    foot: player.foot,
                  }}
                  openImage={openImage}
                />
              ))}
          </div>
        )}

        {/* *****OBRANA***** */}
        <h3 className="center">Obrana</h3>
        {players.filter(
          (player) =>
            player.position === "Lijevi bek" ||
            player.position === "Stoper" ||
            player.position === "Desni bek"
        ).length === 0 ? (
          <div className="info-box">Nema obrambenih igrača.</div>
        ) : (
          <div className="team-grid">
            {players
              .filter(
                (player) =>
                  player.position === "Lijevi bek" ||
                  player.position === "Stoper" ||
                  player.position === "Desni bek"
              )
              .sort((a, b) => Number(a.number) - Number(b.number))
              .map((player) => (
                <Players
                  key={player.id}
                  image={player.image || NoPlayer}
                  name={player.name}
                  number={player.number}
                  info={{
                    birth: player.birth,
                    position: player.position,
                    foot: player.foot,
                  }}
                  openImage={openImage}
                />
              ))}
          </div>
        )}

        {/* *****GOLMANI***** */}
        <h3 className="center">Golmani</h3>
        {players.filter((player) => player.position === "Golman").length ===
        0 ? (
          <div className="info-box">Nema golmana.</div>
        ) : (
          <div className="team-grid">
            {players
              .filter((player) => player.position === "Golman")
              .sort((a, b) => Number(a.number) - Number(b.number))
              .map((player) => (
                <Players
                  key={player.id}
                  image={player.image || NoPlayer}
                  name={player.name}
                  number={player.number}
                  info={{
                    birth: player.birth,
                    position: player.position,
                    foot: player.foot,
                  }}
                  openImage={openImage}
                />
              ))}
          </div>
        )}

        {/* *****TRENER***** */}
        <h2 className="add-margin">Trener</h2>
        {coaches.length === 0 ? (
          <div className="info-box">Nema trenera.</div>
        ) : (
          <div className="team-grid">
            {coaches.map((coach) => (
              <Coach
                key={coach.id}
                image={coach.image || NoPlayer}
                name={coach.name}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function Players({ image, name, number, info, openImage }) {
  const handleClick = () => {
    openImage(image, info);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="player-box" onClick={handleClick}>
      <img
        src={image}
        alt={name}
        onContextMenu={preventContextMenu}
        style={{ userSelect: "none" }}
      />
      <h3>{name}</h3>
      <p>{number}</p>
    </div>
  );
}

function Coach({ image, name }) {
  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="player-box">
      <img
        src={image}
        alt={name}
        onContextMenu={preventContextMenu}
        style={{ userSelect: "none" }}
      />
      <h3>{name}</h3>
    </div>
  );
}
