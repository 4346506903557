import React, { useState } from "react";
import { db } from "../../../firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import Main from "../../Pages/Home/Main";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique IDs

const AddQuiz = ({ onClose }) => {
  const [questions, setQuestions] = useState([]); // Initialize with an empty array
  const [currentQuestion, setCurrentQuestion] = useState({
    id: "",
    text: "",
    answers: ["", "", "", ""],
    correctAnswerIndex: 0,
  }); // State for current question
  const [saveMessage, setSaveMessage] = useState(""); // State for feedback message
  const [isQuizStarted, setIsQuizStarted] = useState(false); // State for quiz status

  // Handle form input change
  const handleInputChange = (field, value, answerIndex = null) => {
    if (field === "text") {
      setCurrentQuestion({ ...currentQuestion, text: value });
    } else if (field === "answer" && answerIndex !== null) {
      const updatedAnswers = currentQuestion.answers.map((answer, index) =>
        index === answerIndex ? value : answer
      );
      setCurrentQuestion({ ...currentQuestion, answers: updatedAnswers });
    } else if (field === "correctAnswerIndex") {
      setCurrentQuestion({ ...currentQuestion, correctAnswerIndex: value });
    }
  };

  // Add new question to the list
  const addQuestionToList = () => {
    if (
      currentQuestion.text.trim() === "" ||
      currentQuestion.answers.some((a) => a.trim() === "")
    ) {
      setSaveMessage("Molimo popunite pitanje i sve odgovore.");
      return;
    }

    setQuestions([...questions, { ...currentQuestion, id: uuidv4() }]); // Add question with a unique ID
    setCurrentQuestion({
      id: "",
      text: "",
      answers: ["", "", "", ""],
      correctAnswerIndex: 0,
    }); // Reset current question
    setSaveMessage(""); // Clear save message
  };

  // Save questions to Firebase
  const saveQuiz = async () => {
    if (questions.length === 0) {
      setSaveMessage("Nema pitanja za spremanje.");
      return;
    }

    try {
      const quizRef = collection(db, "kviz");
      await addDoc(quizRef, { questions });
      setSaveMessage("Kviz je uspješno spremljen!");
      setQuestions([]); // Clear questions after saving
    } catch (error) {
      setSaveMessage("Dogodila se greška pri spremanju kviza.");
    }
  };

  // Start the quiz
  const startQuiz = () => {
    if (questions.length === 0) {
      setSaveMessage("Nema pitanja za pokretanje kviza.");
    } else {
      setIsQuizStarted(true);
    }
  };

  // Close the quiz
  const closeQuiz = () => {
    setIsQuizStarted(false);
    onClose();
  };

  // Remove question from the list
  const removeQuestion = (id) => {
    setQuestions(questions.filter((question) => question.id !== id));
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI KVIZ"
      />
      <div className="section">
        {!isQuizStarted ? (
          <>
            <button className="start-btn" onClick={startQuiz}>
              Pokreni Kviz
            </button>
            <div className="form">
              <input
                type="text"
                placeholder="Napiši pitanje"
                value={currentQuestion.text}
                onChange={(e) => handleInputChange("text", e.target.value)}
                required
              />
              <div className="answers-section">
                {currentQuestion.answers.map((answer, answerIndex) => (
                  <div key={answerIndex}>
                    <input
                      type="text"
                      placeholder={`Odgovor ${answerIndex + 1}`}
                      value={answer}
                      onChange={(e) =>
                        handleInputChange("answer", e.target.value, answerIndex)
                      }
                      required
                    />
                  </div>
                ))}
              </div>
              <label>Točan odgovor: </label>
              <select
                value={currentQuestion.correctAnswerIndex}
                onChange={(e) =>
                  handleInputChange(
                    "correctAnswerIndex",
                    Number(e.target.value)
                  )
                }
              >
                {currentQuestion.answers.map((_, answerIndex) => (
                  <option key={answerIndex} value={answerIndex}>
                    Odgovor {answerIndex + 1}
                  </option>
                ))}
              </select>
              <button className="add-btn" onClick={addQuestionToList}>
                Dodaj pitanje
              </button>
              {saveMessage && <p className="save-message">{saveMessage}</p>}
            </div>

            <div className="section">
              <h3 className="center">Lista pitanja:</h3>
              {questions.map((question) => (
                <div key={question.id} className="question-item">
                  <p>{question.text}</p>
                  <div className="answers-section">
                    {question.answers.map((answer, index) => (
                      <p key={index}>{` ${index + 1}: ${answer}`}</p>
                    ))}
                  </div>
                  <p>
                    <strong>Točan odgovor:</strong>{" "}
                    {question.correctAnswerIndex + 1}
                  </p>
                  <button
                    className="remove-btn"
                    onClick={() => removeQuestion(question.id)}
                  >
                    Obriši
                  </button>
                </div>
              ))}
            </div>
            <button className="add-btn publish" onClick={saveQuiz}>
              Spremi kviz
            </button>
            <button className="close-btn" onClick={closeQuiz}>
              Zatvori kviz
            </button>
          </>
        ) : (
          <p>Kviz trenutno nije dostupan. Vrati se na naslovnu.</p>
        )}
      </div>
    </>
  );
};

export default AddQuiz;
