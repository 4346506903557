import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import Main from "../../Pages/Home/Main";
// Slike
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

const AddStandings = () => {
  const [standingsData, setStandingsData] = useState([]);
  const [editingTeam, setEditingTeam] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({
    bod: "",
    raz: "",
    utk: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const kluboviData = docSnap.data().klubovi;

          // Sort teams by points and goal difference
          const sortedData = kluboviData.sort((a, b) => {
            if (parseInt(b.bod) !== parseInt(a.bod)) {
              return parseInt(b.bod) - parseInt(a.bod);
            }
            return parseInt(b.raz) - parseInt(a.raz);
          });

          setStandingsData(sortedData);
        } else {
          setWarningMessage("Nema podataka o klubovima.");
        }
      } catch (error) {
        setWarningMessage("Greška u dohvačanju podataka.");
      }
    };

    fetchData();
  }, []);

  // Handle when a team is clicked for editing
  const handleEditClick = (team) => {
    setEditingTeam(team.id);
    setUpdatedValues({
      bod: team.bod,
      raz: team.raz,
      utk: team.utk,
    });
    setSuccessMessage(""); // Clear messages when editing
    setWarningMessage("");
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle save click to update club data
  const handleSaveClick = async (teamId) => {
    const docRef = doc(db, "podsused", "podsused");

    try {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const klubovi = docSnap.data().klubovi;

        // Find and update the specific club in the array
        const updatedKlubovi = klubovi.map((team) =>
          team.id === teamId
            ? {
                ...team,
                bod: parseInt(updatedValues.bod), // Update the points
                raz: parseInt(updatedValues.raz), // Update the goal difference
                utk: parseInt(updatedValues.utk), // Update the games played
              }
            : team
        );

        // Update the klubovi array in Firestore
        await updateDoc(docRef, { klubovi: updatedKlubovi });

        // Sort updated standings and update local state
        const sortedStandings = updatedKlubovi.sort((a, b) => {
          if (parseInt(b.bod) !== parseInt(a.bod)) {
            return parseInt(b.bod) - parseInt(a.bod);
          }
          return parseInt(b.raz) - parseInt(a.raz);
        });

        setStandingsData(sortedStandings);
        setEditingTeam(null);
        setSuccessMessage("Podaci uspješno ažurirani!");
        setWarningMessage("");
      } else {
        setWarningMessage("Dokument ne postoji.");
      }
    } catch (error) {
      setWarningMessage("Greška u ažuriranju podataka.");
      setSuccessMessage("");
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="UREDI TABLICU"
      />
      <div className="section">
        {/* Success and Warning Messages */}
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
        {warningMessage && (
          <div className="warning-message">{warningMessage}</div>
        )}

        <button
          className="go-back-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          Vrati se nazad
        </button>
        <ul className="table table-six">
          {/* Header row */}
          <li className="row">
            <h5>#</h5>
            <h5>Klub</h5>
            <h5>Ut.</h5>
            <h5>G +/-</h5>
            <h5>Bod</h5>
          </li>
          {/* List of teams */}
          {standingsData.map((team, index) => {
            const rowClass = team.klub.includes("NK Podsused")
              ? "row podsused"
              : "row";

            return (
              <li key={team.id} className={rowClass}>
                {editingTeam === team.id ? (
                  <>
                    <h5>{index + 1}</h5>
                    <p>
                      <img src={team.logoUrl} alt={`${team.klub} logo`} />
                      {team.klub}
                    </p>
                    <input
                      type="number"
                      name="utk"
                      value={updatedValues.utk}
                      onChange={handleInputChange}
                      placeholder="Ut."
                    />
                    <input
                      type="number"
                      name="raz"
                      value={updatedValues.raz}
                      onChange={handleInputChange}
                      placeholder="G +/-"
                    />
                    <input
                      type="number"
                      name="bod"
                      value={updatedValues.bod}
                      onChange={handleInputChange}
                      placeholder="Bod"
                    />
                    <button
                      className="add-btn"
                      onClick={() => handleSaveClick(team.id)}
                    >
                      Spremi
                    </button>
                  </>
                ) : (
                  <>
                    <h5>{index + 1}</h5>
                    <p>
                      <img src={team.logoUrl} alt={`${team.klub} logo`} />
                      {team.klub}
                    </p>
                    <p>{team.utk}</p>
                    <p>{team.raz}</p>
                    <p>{team.bod}</p>
                    <button
                      className="add-btn"
                      onClick={() => handleEditClick(team)}
                    >
                      Uredi
                    </button>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default AddStandings;
