import React from "react";
import { Link } from "react-router-dom";
// Images
import NKPodsused from "../../../Assets/Images/Nk-Podsused-Logo.png";

export default function NotFoundPage() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="section error-page">
        <div>
          {" "}
          <img src={NKPodsused} alt="NK Podsused" />
          <h2>STRANICA NIJE PRONAĐENA!</h2>
          <p>Provjerite putanju koju ste upisali.</p>
          <Link className="btn" to="/" onClick={() => handleClick("/")}>
            NATRAG NA NASLOVNU
          </Link>
        </div>
      </div>
    </>
  );
}
