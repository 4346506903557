import React, { useEffect, useState } from "react";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Components
import Matches from "../Home/Matches";
import Main from "../Home/Main";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";

export default function Season() {
  const [selectedRound, setSelectedRound] = useState("");
  const [matches, setMatches] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "NK Podsused | Seniori Sezona 2023/2024";

    const fetchMatches = async () => {
      try {
        const docRef = doc(db, "podsused", "podsused");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const matchList = data.utakmice || [];
          const uniqueRounds = new Set(); // Skup za jedinstvena kola

          matchList.forEach((match) => {
            uniqueRounds.add(match.round);
          });

          // Sortiraj utakmice tako da najnovije kolo bude prvo
          matchList.sort((a, b) => {
            const roundA = parseInt(a.round.split(".")[0]);
            const roundB = parseInt(b.round.split(".")[0]);
            return roundB - roundA;
          });

          setMatches(matchList);

          // Sortiraj kola u silaznom redoslijedu
          const sortedRounds = Array.from(uniqueRounds).sort((a, b) => {
            const roundA = parseInt(a.split(".")[0]);
            const roundB = parseInt(b.split(".")[0]);
            return roundB - roundA;
          });

          setRounds(sortedRounds);

          if (matchList.length > 0) {
            // Postavi zadnje dodano kolo kao odabrano
            setSelectedRound(matchList[0].round);
          }
        } else {
          setMessage("Dokument ne postoji.");
        }
      } catch (error) {
        setMessage("Došlo je do greške prilikom dohvata utakmica.");
      }
    };

    fetchMatches();
  }, []);

  const scrollToMatch = (kolo) => {
    const matchId = `match-${kolo}`;
    const matchElement = document.getElementById(matchId);

    if (matchElement) {
      const scrollYOffset =
        matchElement.getBoundingClientRect().top - window.innerHeight / 2;
      window.scrollBy({ top: scrollYOffset, behavior: "smooth" });
    }
  };

  const handleSelectChange = (e) => {
    const selectedKolo = e.target.value;
    setSelectedRound(selectedKolo);
    scrollToMatch(selectedKolo);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
      />
      <div className="section">
        <select
          className="season-search"
          value={selectedRound}
          onChange={handleSelectChange}
        >
          {rounds.map((round) => (
            <option key={round} value={round}>
              {round}
            </option>
          ))}
        </select>
        <div className="season-matches-top">
          {matches.map((match) => (
            <div key={match.id} id={`match-${match.round}`}>
              <Matches matches={[match]} />
            </div>
          ))}
        </div>
        {message && <div className="error-message">{message}</div>}
      </div>
    </>
  );
}
