import React from "react";
import { Navigate } from "react-router-dom";
import { useMembersAuth } from "./MembersAuthProvider";

const MembersPrivateRoute = ({ children }) => {
  const { isAuthenticated, loading } = useMembersAuth();

  // Ako je još u tijeku učitavanje, možete prikazati loader ili slično
  if (loading) {
    return <div>Učitavanje...</div>;
  }

  // Provjerite je li korisnik član
  if (!isAuthenticated) {
    return <Navigate to="/članovi-kluba/prijava" />;
  }

  return children; // Ako je korisnik član, renderirajte djecu (komponentu)
};

export default MembersPrivateRoute;
