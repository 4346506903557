import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./Styles/App.scss";
import {
  Navigation,
  Home,
  Footer,
  Seniori,
  About,
  Season,
  Kadeti,
  Limaci,
  Zagici,
  Pioniri,
  Contact,
  PrivacyPolicy,
  TermsOfUse,
  Gallery,
  BackToTopButton,
  /*   Quiz, */
  RasporedTreninga,
  Veterani,
  OstaloSeason,
  ErrorPage,
  LoadingScreen,
  NewsDetails,
  NewsPages,
  Oprema,
  Member,
  Donations,
  AddNews,
  Login,
  AddMatches,
  AddGallery,
  AddPlayers,
  AddStandings,
  AddSelections,
  AddTrainings,
  AddSeniors,
  AddVeterans,
  AddTrenere,
  MlPioniri,
  ShutDownPage,
  AddQuiz,
  MembersLogin,
  MembersPrivateRoute,
  MembersHome,
  AdminMembers,
  AddKadeti,
} from "./Components";
import Sponsors from "./Components/Pages/Home/Sponsors";
import NotFoundPage from "./Components/Partials/Errors/NotFoundPage";
import AdminDashboard from "./Components/Admin/Home/AdminDashboard";

import AuthProvider from "./Components/Admin/LogInOut/AuthProvider";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig"; // Firebase config
import AdminPrivateRoute from "./Components/Admin/LogInOut/AdminPrivateRoute";
import { MembersAuthProvider } from "./Components/MembersAccount/MembersAuthProvider";
import AdminAuthProvider from "./Components/Admin/LogInOut/AdminAuthProvider";

const App = () => {
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [isShutdown, setIsShutdown] = useState(false);

  useEffect(() => {
    const fetchShutdownStatus = async () => {
      const docRef = doc(db, "settings", "shutdown");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setIsShutdown(docSnap.data().shutdown);
      }
    };

    fetchShutdownStatus();
  }, []);

  if (isShutdown) {
    return <ShutDownPage />;
  }

  return (
    <AuthProvider>
      <AdminAuthProvider>
        <BrowserRouter>
          <LoadingScreen setLoadingComplete={() => setLoadingComplete(true)} />
          {loadingComplete && (
            <>
              <Navigation />
              <BackToTopButton />
              <MembersAuthProvider>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/raspored-treninga"
                    element={<RasporedTreninga />}
                  />
                  {/* SEASON */}
                  <Route path="/seniori" element={<Seniori />} />
                  <Route path="/ostale-utakmice" element={<OstaloSeason />} />
                  {/* SEASON */}
                  <Route path="/utakmice" element={<Season />} />
                  <Route path="/ostale-utakmice" element={<OstaloSeason />} />
                  {/* ABOUT */}
                  <Route path="/o-klubu" element={<About />} />
                  {/* SELECTIONS */}
                  <Route path="/veterani" element={<Veterani />} />
                  <Route path="/kadeti" element={<Kadeti />} />
                  <Route path="/mlađi-pioniri" element={<MlPioniri />} />
                  <Route path="/pioniri" element={<Pioniri />} />
                  <Route path="/limači" element={<Limaci />} />
                  <Route path="/zagići" element={<Zagici />} />
                  {/* OPREMA */}
                  <Route path="/oprema" element={<Oprema />} />
                  {/* MEMBER */}
                  <Route path="/postani-član" element={<Member />} />
                  {/* DONATIONS */}
                  <Route path="/donacije" element={<Donations />} />
                  {/* NOVOSTI */}
                  {/*  <Route path="/kviz" element={<Quiz />} /> */}
                  {/* NEWS */}
                  <Route path="/novosti" element={<NewsPages />} />
                  <Route path="/novosti/:slug" element={<NewsDetails />} />

                  {/* CONTACT */}
                  <Route path="/kontakt" element={<Contact />} />
                  {/* GALLERY */}
                  <Route path="/galerija" element={<Gallery />} />
                  {/* PRIVACY POLICY & TERMS OF USE */}
                  <Route
                    path="/politika-privatnosti"
                    element={<PrivacyPolicy />}
                  />
                  <Route path="/uvjeti-korištenja" element={<TermsOfUse />} />
                  {/* ERRORS */}
                  <Route path="*" element={<NotFoundPage />} />
                  <Route
                    path="error"
                    element={<ErrorPage error="Nepoznata greška" />}
                  />
                  {/* ADMIN */}
                  <Route path="/admin/prijava" element={<Login />} />
                  <Route
                    path="/admin/dodaj-novost"
                    element={
                      <AdminPrivateRoute>
                        <AddNews />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/dodaj-utakmice-seniori"
                    element={
                      <AdminPrivateRoute>
                        <AddMatches />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/dodaj-ostale-utakmice"
                    element={
                      <AdminPrivateRoute>
                        <AddSelections />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/dashboard"
                    element={
                      <AdminPrivateRoute>
                        <AdminDashboard />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-galeriju"
                    element={
                      <AdminPrivateRoute>
                        <AddGallery />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/dodaj-strijelce"
                    element={
                      <AdminPrivateRoute>
                        <AddPlayers />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-tablicu"
                    element={
                      <AdminPrivateRoute>
                        <AddStandings />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-treninge"
                    element={
                      <AdminPrivateRoute>
                        <AddTrainings />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-seniore"
                    element={
                      <AdminPrivateRoute>
                        <AddSeniors />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-kadete"
                    element={
                      <AdminPrivateRoute>
                        <AddKadeti />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-kviz"
                    element={
                      <AdminPrivateRoute>
                        <AddQuiz />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-veterane"
                    element={
                      <AdminPrivateRoute>
                        <AddVeterans />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-trenere"
                    element={
                      <AdminPrivateRoute>
                        <AddTrenere />
                      </AdminPrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/uredi-članove"
                    element={
                      <AdminPrivateRoute>
                        <AdminMembers />
                      </AdminPrivateRoute>
                    }
                  />
                  {/* Member rute unutar MembersAuthProvider */}

                  <Route
                    path="/članovi-kluba/prijava"
                    element={<MembersLogin />}
                  />
                  <Route
                    path="/članovi-kluba/naslovna"
                    element={
                      <MembersPrivateRoute>
                        <MembersHome />
                      </MembersPrivateRoute>
                    }
                  />
                </Routes>
              </MembersAuthProvider>
              <Sponsors />
              <Footer />
            </>
          )}
        </BrowserRouter>
      </AdminAuthProvider>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
