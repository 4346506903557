import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../../../firebaseConfig"; // Provjerite putanju
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

// Kreiramo AuthContext
const AuthContext = createContext();

// Custom hook za lakši pristup AuthContextu
export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState(null);

  const fetchUserRole = async (email) => {
    const userRoles = {
      "podsused.nk@gmail.com": "admin",
    };

    return userRoles[email] || "member"; // Default to "member" if not found
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuthenticated(true);
        setCurrentUser(user);
        const userRole = await fetchUserRole(user.email);
        setRole(userRole);
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
        setRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const userRole = await fetchUserRole(email); // Dobijamo ulogu prilikom prijave
      setRole(userRole);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, currentUser, role, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
